import { Colors } from "types";
import { BiMailSend, BiPhoneCall, BiWorld } from "react-icons/bi";

type Props = {
  phone: string;
  mail: string;
  website: string;
  colors: Colors;
};

function InfosPanel({ phone, mail, website, colors }: Props) {
  const url = website && new URL(website);

  return (
    <div className="infos-panel-container">
      <div
        className="infos-panel-layout "
        style={{ background: colors.background, color: colors.fontcolor }}
      >
        <div className="info-container">
          <BiPhoneCall
            size={25}
            fill={colors.fontcolor}
            style={{ justifySelf: "center" }}
          />

          <div className="infos-panel-text ">
            <a href={`tel:${phone}`} style={{ color: colors.fontcolor }}>
              {phone}
            </a>
          </div>
        </div>
        {/* <div className="infos-panel-line-phone-mail"></div> */}
        <div className="info-container">
          <BiMailSend
            fill={colors.fontcolor}
            size={25}
            style={{ justifySelf: "center" }}
          />
          <div className="infos-panel-text">
            <a href={`mailto:${mail}`} style={{ color: colors.fontcolor }}>
              {mail}
            </a>
          </div>
        </div>
        {/* <div className="infos-panel-line-mail-website"></div> */}

        <div className="info-container info-container-last">
          <BiWorld
            size={25}
            fill={colors.fontcolor}
            style={{ justifySelf: "center" }}
          />
          <div className="infos-panel-text">
            <a
              href={`${url ? url.href : null}`}
              style={{ color: colors.fontcolor }}
            >
              {url ? url.hostname : null}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfosPanel;
