import { useTranslation } from "react-i18next";

interface Language {
  nativeName: string;
}
interface LanguagesObject {
  [key: string]: Language;
}

const lngs: LanguagesObject = {
  fr: { nativeName: "Français" },
  en: { nativeName: "English" },
};

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div>
      <div className="btn-group btn-group-horizontal">
        {Object.keys(lngs).map((lng) => (
          <button
            className={`btn btn-sm btn-primary btn-outline  ${
              i18n.resolvedLanguage === lng ? "btn-active" : ""
            }`}
            type="submit"
            onClick={() => i18n.changeLanguage(lng)}
            key={lng}
            data-cy={`language-switcher-${lng}`}
          >
            {" "}
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
    </div>
  );
}

export default LanguageSwitcher;
