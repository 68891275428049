interface ButtonProps {
  onClick: () => any;
  title: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

export const PrimaryButton = ({
  onClick,
  title,
  disabled,
  type,
}: ButtonProps) => {
  return (
    <button
      className="button"
      onClick={onClick}
      disabled={disabled}
      type={type ? type : "button"}
    >
      <span>{title}</span>
    </button>
  );
};

export const SecondaryButton = ({ onClick, title, disabled }: ButtonProps) => {
  return (
    <button
      className="button-secondary"
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <span>{title}</span>
    </button>
  );
};
