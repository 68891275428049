import BusinessCard from "components/BusinessProfile/BusinessCard";
import ContactButtons from "components/BusinessProfile/ContactButtons";
import InfosPanel from "components/BusinessProfile/InfosPanel";
import SocialButtons from "components/BusinessProfile/SocialButtons";
import { blueClassic } from "components/palette";
import { useMobileMediaQuery } from "helpers/responsiveHelper";
import { Card } from "types";

/**
 * This component is used to display the complete card of a user, as it's present on "app".
 */

interface ICurrentUserCard {
  card: Card;
}

const CurrentUserCard = ({ card }: ICurrentUserCard) => {
  return (
    <div className="flex flex-col justify-between w-80">
      <BusinessCard
        firstname={card.firstname}
        lastname={card.lastname}
        job={card.job}
        company={card.company}
        imgUrl={card.imageUrl}
        colors={
          card.colors ? card.colors.businesscard : blueClassic.businesscard
        }
      />
      <SocialButtons card={card} />
      <ContactButtons colors={card.colors} />
      <div>
        <InfosPanel
          phone={card.personalphone !== "" ? card.personalphone : card.prophone}
          mail={card.proemail}
          website={card.mainwebsite}
          colors={card.colors?.infospanel as keyof undefined}
        />
      </div>
    </div>
  );
};

export default CurrentUserCard;
