import { AuthContext } from "AuthProvider";
import BusinessCard from "components/BusinessProfile/BusinessCard";
import Loading from "components/Loading";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Logo, Template } from "types";
import { avatars } from "components/template/avatars";
import { useTranslation } from "react-i18next";

function Templates() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.template.templates",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState<(Template & Logo)[]>([]);
  let companyid = "";
  const authContext = useContext(AuthContext);
  if (authContext) {
    companyid = authContext.companyid;
  }
  const history = useHistory();

  useEffect(() => {
    const getTemplates = async () => {
      const db = getFirestore();
      const storage = getStorage();
      const templateRef = collection(db, "templates");
      const templateQuery = query(
        templateRef,
        where("companyid", "==", companyid)
      );
      const templateSnapshot = await getDocs(templateQuery);
      templateSnapshot.forEach(async (elmt) => {
        const elt = elmt.data() as Template & Logo;
        let logoUrl = "";
        if (elt.logo) {
          const logoRef = ref(storage, `${companyid}/${elmt.id}.jpg`);
          logoUrl = await getDownloadURL(logoRef);
        }

        setTemplates((c) => [
          ...c,
          { ...elt, id: elmt.id, logoUrl: elt.logo ? logoUrl : "" },
        ]);
      });
    };

    getTemplates();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleNavigationToTemplateInfo = (templateid: string) => {
    history.push(`templates/${templateid}`);
  };

  const handleNavigationToNewTemplate = () => {
    history.push(`templates/new`);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="userinfo-actionbar">
            <button
              className="btn btn-secondary btn-sm mr-2"
              onClick={handleNavigationToNewTemplate}
            >
              {t("new_template")}
            </button>
          </div>
          <div className="template-main-container">
            <div className="userinfo-help-container ">
              <h1 className="userinfo-help-title">{t("tips")}</h1>
              <ol>
                <li>{t("tips_1")}</li>
                <li>{t("tips_2")}</li>
                <li>{t("tips_3")}</li>
              </ol>
            </div>

            <div className="templates-cardlist">
              {templates.map((template) => {
                const randIdx = Math.floor(Math.random() * avatars.length);

                return (
                  <div
                    className="templates-businesscard"
                    onClick={() => handleNavigationToTemplateInfo(template.id)}
                  >
                    <BusinessCard
                      colors={template.colors.businesscard}
                      company={t("company")}
                      firstname={t("firstname")}
                      lastname={t("lastname")}
                      imgUrl={avatars[randIdx]}
                      job={t("job")}
                    />
                    <div className="templates-name">{template.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Templates;
