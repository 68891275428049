import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { capitalizeFirstLetter } from "helpers/functionsHelper";
import { useTranslation } from "react-i18next";
function TopBar() {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.top_bar",
  });
  const history = useHistory();
  const [title, setTitle] = useState<string>("JustOneCard");

  useEffect(() => {
    let section = history.location.pathname.split("/dashboard/")[1];

    section = section.split("/")[0];
    switch (section) {
      case "users":
        section = t("employees");
        break;
      case "templates":
        section = t("customization");
        break;
      case "stats":
        section = t("statistics");
        break;
      case "help":
        section = t("help");
        break;
      case "settings":
        section = t("settings");
        break;
      default:
        break;
    }
    section = capitalizeFirstLetter(section);
    setTitle(section);
  }, [history.location.pathname, t]);
  return (
    <div className="topbar-container">
      <span className="topbar-title">{title}</span>
    </div>
  );
}

export default TopBar;
