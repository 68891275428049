import { useRef, useEffect, RefObject, ReactChildren } from "react";

export default function useClickOutsideFunction(onClickOutside: () => void){
    let ref:RefObject<HTMLDivElement> = useRef(null);
  
    useEffect(() => {
      const handleClick = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
    return ref
  };