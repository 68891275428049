import CardForm from "components/form/CardForm";
import React, { useEffect } from "react";

function EditCard() {
  useEffect(() => {
    window.analytics.page("Profile edit");
  }, []);
  return (
    <>
      <CardForm />
    </>
  );
}

export default EditCard;
