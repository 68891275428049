import { useTranslation } from "react-i18next";
import { BiPlusCircle } from "react-icons/bi";
import { Colors } from "types";

var ReactTextfit: any = require("react-textfit");

type Props = {
  colors: Colors;
};

function AddContact({ colors }: Props) {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.business_profile.add_contact",
  });
  return (
    <>
      <div className="add-contact-container ">
        <div
          className="add-contact-layout "
          style={{ background: colors.background }}
        >
          <BiPlusCircle
            size={25}
            style={{ justifySelf: "center" }}
            color={colors.fontcolor}
          />

          <div className="add-contact-text" style={{ color: colors.fontcolor }}>
            <ReactTextfit.Textfit>{t("main")}</ReactTextfit.Textfit>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddContact;
