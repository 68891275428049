import React, { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { matchSorter } from "match-sorter";
import { useMobileMediaQuery } from "helpers/responsiveHelper";
import { MdOutlineSearch } from "react-icons/md";
import { useTranslation } from "react-i18next";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="usertable-search-container">
      <div className="usertable-search-icon-container"></div>
      <div class="form-control">
        <label class="input-group input-group-sm">
          <span>
            <MdOutlineSearch color="black" />
          </span>
          <input
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={` prénom, nom...`}
            style={{
              fontSize: "1.1rem",
              border: "0",
            }}
            className="input input-bordered input-sm"
          />{" "}
        </label>
      </div>
    </div>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  const { t } = useTranslation("translation", {
    keyPrefix: "components.user_table",
  });

  return (
    <div class="form-control ">
      <label class="flex flex-row justify-center">
        <input
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={t("search_placeholder", { count })}
          className="input input-xs"
        />
      </label>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function UserTable({ columns, data, handleNavigation }) {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const prevUserTableIndex = localStorage.getItem("user_table_index");

  const {
    getTableProps, // required, used to resolve any props needed for your table wrapper
    getTableBodyProps, // required,
    headerGroups, // array of normalized headergroups
    prepareRow, // required, preparing row for rendering
    // state, // memoized, final state object of the table
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state,
  } = useTable(
    {
      columns, // required, must be memoized, core columns, support nested
      data, // required, memoized, data array to display
      defaultColumn, // optional, yo add global(default) column properties like a filter
      filterTypes,
      initialState: { pageIndex: prevUserTableIndex | 0, pageSize: 15 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    usePagination // usePagination !
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  // Store the index of the page in local storage
  useEffect(() => {
    localStorage.setItem("user_table_index", state.pageIndex);
  }, [state.pageIndex]);

  const isMobile = useMobileMediaQuery();

  const { t } = useTranslation("translation", {
    keyPrefix: "components.user_table",
  });
  return (
    <>
      <div className="usertable-filter">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div className="usertable-container">
        <table {...getTableProps({ className: "" })}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps({
                  className: "usertable-header",
                })}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        textAlign: "center",
                        height: 60,
                      },
                    })}
                  >
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter && !isMobile
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps({ className: "bg-white" })}>
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps({
                    className: `usertable-row ${
                      row.id % 2 === 0 ? "usertable-background-cell" : ""
                    }`,
                  })}
                  onClick={() => {
                    handleNavigation(row.original.id);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: `usertable-cell `,
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            maxWidth: cell.column.maxWidth,
                          },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <span className="text-center">{t("tips")}</span>

        <div className="flex flex-row justify-end space-x-2 mr-2 mt-2">
          <div>
            <select
              className="select w-full max-w-xs select-sm"
              value={state.pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[15, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t("see")} {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="btn-group">
            <button
              className="btn btn-sm btn-outline"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              «
            </button>
            <button className="btn btn-primary no-animation no-hover btn-sm">
              {" "}
              {t("page")} {state.pageIndex + 1} / {pageOptions.length}
            </button>
            <button
              className="btn btn-sm btn-outline"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              »
            </button>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default UserTable;
