import { useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { translateFirebaseAuthError } from "helpers/functionsHelper";
import toast from "react-hot-toast";
import {
  MyPasswordTextInput,
  MyTextInput,
} from "components/form/FormComponents";
import { Form, Formik } from "formik";
import { signInPasswordValidationSchema } from "components/form/schemas/SignInValidationSchema";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "components/LanguageSwitcher";

function SignIn() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.auth.sign_in",
  });

  const auth = getAuth();

  useEffect(() => {
    window.analytics.page("Login");
  }, []);

  const history = useHistory();

  interface handleSignInProps {
    email: string;
    password: string;
  }
  const handleSignIn = async ({ email, password }: handleSignInProps) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      window.analytics.identify(user.uid, {
        email: user.email,
      });
      history.push("/dashboard");
    } catch (e: any) {
      toast.error(translateFirebaseAuthError(e.code));
    }
  };

  const urlParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const password = queryParams.get("password");
    if (email === null || password === null) {
      return { email: "", password: "" };
    }
    handleSignIn({ email, password });
    return { email, password };
  };

  const handleNavigationToForgotPassword = () => {
    history.push("reset-password");
  };

  const PasswordInput = ({
    handleNavigationToForgotPassword,
  }: {
    handleNavigationToForgotPassword: () => void;
  }) => {
    return (
      <div className="flex flex-col w-full items-center">
        <MyPasswordTextInput
          label={t("password")}
          name="password"
          data-cy="password"
        />

        <button
          className="btn btn-primary btn-link btn-xs self-end mt-[-1rem] mr-3"
          onClick={handleNavigationToForgotPassword}
          type="button"
          data-cy="forgot-password-button"
        >
          {t("forgot_password")}
        </button>
      </div>
    );
  };

  return (
    <div className=" flex flex-col justify-center items-center mt-10 ">
      <Formik
        initialValues={{
          email: urlParams().email,
          password: urlParams().password,
        }}
        enableReinitialize={true}
        validationSchema={signInPasswordValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSignIn(values);

          setSubmitting(false);
        }}
      >
        {({ submitForm }) => {
          return (
            <Form>
              <div className="form-container">
                <div className="form-description-container">
                  <div className="form-description-title">{t("title")}</div>
                  <div className="form-description-subtitle">
                    {t("subtitle")}
                  </div>
                </div>
                <div className="w-full flex flex-col items-center m-4">
                  <MyTextInput
                    label={t("email")}
                    name="email"
                    type="email"
                    data-cy="username"
                  />
                  <PasswordInput
                    handleNavigationToForgotPassword={
                      handleNavigationToForgotPassword
                    }
                  />
                </div>

                <div className="form-submit-container">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-cy="submit"
                  >
                    {t("button_to_sign_in")}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="absolute bottom-10">
        <LanguageSwitcher />
      </div>
    </div>
  );
}

export default SignIn;
