import * as Yup from "yup";

export const addressSchema = Yup.object().shape({
  label: Yup.string(),
  street: Yup.string(),
  city: Yup.string(),
  postalcode: Yup.string(),
  countryregion: Yup.string(),
});

export const validationSchema = Yup.object().shape({
  firstname: Yup.string().max(15, "15 caractères maximum").required("Requis"),
  lastname: Yup.string().max(20, "20 caractères maximum").required("Requis"),
  company: Yup.string(),
  job: Yup.string(),
  mainwebsite: Yup.string().url("Veuillez entrez un url valide ( http(s):// )"),
  prophone: Yup.string()
  .matches(RegExp("^[+][0-9]+$"), "'+' (ex: +33611111111) obligatoire"),
  personalphone: Yup.string().matches(
    RegExp("^[+][0-9]+$"),
    "'+' obligatoire (ex: +33611111111)"
  ),
  officephone: Yup.string().matches(
    RegExp("^[+][0-9]+$"),
    "'+' obligatoire (ex: +33611111111)"
  ),
  proemail: Yup.string()
    .email("Veuillez saisir un email valide")
    .required("Requis"),
  personalemail: Yup.string().email("Veuillez saisir un email valide"),
  proaddress: addressSchema,
  otheraddress: addressSchema,

  secondwebsite: Yup.string().url(),
});
