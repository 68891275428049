import instagram from "../../assets/social_network/instagram_icon.svg";
import SocialButton from "./SocialButton";
import { Colors } from "types";

type Props = {
  colors: Colors;
};

/**
 * Takes the url and the OS to display the social button
 * @param url url of the profile
 * @param mobileOS OS of the device for handling deeplink
 * @returns Social Button
 */

function InstagramButton({ colors }: Props) {
  return (
    <SocialButton socialNetwork={instagram} alt="instagram" colors={colors} />
  );
}

export default InstagramButton;
