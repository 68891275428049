import { Route, useRouteMatch, Redirect, Switch } from "react-router-dom";
import "./Dashboard.css";
import Users from "pages/Users";
import LeftBar from "components/LeftBar";
import TopBar from "components/TopBar";
import UserInfo from "pages/UserInfo";
import Feedback from "pages/Feedback";
import NewCard from "pages/NewCard";
import CardInfo from "pages/CardInfo";
import EditCard from "pages/EditCard";
import TemplateInfo from "pages/template/TemplateInfo";
import NewTemplate from "pages/template/NewTemplate";
import Settings from "pages/settings/Settings";
import EditPassword from "pages/settings/EditPassword";
import EditTemplate from "pages/template/EditTemplate";
import Templates from "pages/template/Templates";
import Help from "pages/help/Help";
import Statistics from "pages/stats/Statistics";
import { useContext, useEffect } from "react";
import { AuthContext } from "AuthProvider";

export const DashboardRoutes = () => {
  let { path } = useRouteMatch();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    window.$crisp = [];
    window.$crisp.push(["set", "user:email", [authContext?.user?.email]]);
    window.CRISP_WEBSITE_ID = "f294e198-1704-4750-b7af-7c49294dc36b";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);
  return (
    <div className="md:flex md:flex-row flex-col">
      <div className="leftbar-main-container">
        <LeftBar />
      </div>
      <div className="w-screen">
        <TopBar />
        <div className="main-container">
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/users`} from={path} />
            </Route>
            {/* CARD ROUTES IN USER ROUTES */}
            <Route path={`${path}/users/:userid/:cardid/edit`}>
              <EditCard />
            </Route>
            <Route path={`${path}/users/:userid/new`}>
              <NewCard />
            </Route>
            <Route path={`${path}/users/:userid/:cardid`}>
              <CardInfo />
            </Route>

            {/* USER ROUTES */}
            <Route exact path={`${path}/users`}>
              <Users />
            </Route>

            <Route path={`${path}/users/:userid`}>
              <UserInfo />
            </Route>

            {/* TEMPLATE ROUTES */}
            <Route path={`${path}/templates/:templateid/edit`}>
              <EditTemplate />
            </Route>
            <Route path={`${path}/templates/new`}>
              <NewTemplate />
            </Route>
            <Route path={`${path}/templates/:templateid`}>
              <TemplateInfo />
            </Route>
            <Route path={`${path}/templates`}>
              <Templates />
            </Route>

            {/* STATS ROUTES */}
            <Route path={`${path}/stats`}>
              <Statistics />
            </Route>

            {/* FEEDBACK ROUTES */}
            <Route path={`${path}/feedback`}>
              <Feedback />
            </Route>

            {/* HELP ROUTES */}
            <Route path={`${path}/help`}>
              <Help />
            </Route>
            {/* SETTINGS ROUTES */}
            <Route path={`${path}/settings/editpassword`}>
              <EditPassword />
            </Route>
            <Route path={`${path}/settings`}>
              <Settings />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
