import { PrimaryButton } from "components/buttons/buttons";
import { MyTextInput } from "components/form/FormComponents";
import { editPasswordValidationSchema } from "components/form/schemas/EditPasswordValidationSchema";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

function EditPassword() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.settings.edit_password",
  });

  const auth = getAuth();
  const history = useHistory();
  const user = auth.currentUser;

  useEffect(() => {
    window.analytics.page("Edit password");
  }, []);

  interface editPasswordProps {
    lastPassword: string;
    newPassword: string;
    checkNewPassword: string;
  }

  const editPassword = async ({
    lastPassword,
    newPassword,
    checkNewPassword,
  }: editPasswordProps) => {
    if (user === null || !user.email) {
      return;
    }
    if (newPassword.length < 8) {
      toast.error(t("error_lenght"));
      return;
    }
    if (newPassword !== checkNewPassword) {
      toast.error(t("error_match"));
      return;
    }

    const credential = EmailAuthProvider.credential(user.email, lastPassword);
    try {
      await reauthenticateWithCredential(user, credential);
    } catch (e) {
      toast.error(t("error_old_password"));
      return;
    }

    try {
      if (user) {
        await updatePassword(user, newPassword);
        window.analytics.track("Password edited");
        toast.success(t("success"));
        history.goBack();
      }
    } catch (e: any) {
      toast.error(e.code);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className="settings-actionbar">
        <button onClick={handleGoBack} className="cardinfo-button-back ">
          {t("back")}
        </button>
        <div></div>
      </div>
      <Formik
        initialValues={{
          lastPassword: "",
          newPassword: "",
          checkNewPassword: "",
        }}
        enableReinitialize={true}
        validationSchema={editPasswordValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await editPassword(values);

          setSubmitting(false);
        }}
      >
        {({ submitForm }) => {
          return (
            <Form>
              <div className="form-container">
                <div className="form-inputs-container">
                  <MyTextInput
                    label={t("old_password")}
                    name="lastPassword"
                    type="password"
                  />
                  <MyTextInput
                    label={t("new_password")}
                    name="newPassword"
                    type="password"
                  />

                  <MyTextInput
                    label={t("confirm_password")}
                    name="checkNewPassword"
                    type="password"
                  />
                </div>
                <div className="form-submit-container">
                  <PrimaryButton
                    onClick={() => {}}
                    type="submit"
                    title={t("button_to_save")}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default EditPassword;
