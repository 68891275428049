import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomActiveShapePieChart,
  CustomActiveShapePieChartElement,
} from "./CustomActiveShapePieChart";

interface TotalTapsProps {
  companyid: string;
}
function TotalTaps({ companyid }: TotalTapsProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.statistics.total_taps",
  });
  const [taps, setTaps] = useState<CustomActiveShapePieChartElement[]>([]);
  useEffect(() => {
    const db = getFirestore();
    const getTotalTaps = async () => {
      const ownersRef = collection(db, "owners");
      const q = query(ownersRef, where("company.id", "==", companyid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().taps) {
          const docData = doc.data();
          setTaps((c) => [
            ...c,
            {
              name: docData.firstname + " " + docData.lastname,
              value: docData.taps,
            },
          ]);
        }
      });
    };
    getTotalTaps();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="totaltaps-container">
      <div className="text-3xl mt-4 totaltaps-title">{t("title")}</div>
      <div className="totaltaps-subtitle">{t("description")}</div>

      <CustomActiveShapePieChart data={taps} />
      <i className="text-sm">{t("graph_description")}</i>
      <i className="text-sm mb-2"> {t("graph_description_2")}</i>
    </div>
  );
}

export default TotalTaps;
