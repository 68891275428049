export const socialNetworks = [
  {
    title: "Facebook",
    name: "facebook",
    placeholder: "https://www.facebook.com/YOURPROFIL/",
  },
  {
    title: "Instagram",
    name: "instagram",

    placeholder: "https://www.instagram.com/YOURPROFIL/",
  },
  {
    title: "Linkedin",
    name: "linkedin",
    placeholder: "https://www.linkedin.com/in/YOURPROFIL/",
  },
  {
    title: "Youtube",
    name: "youtube",
    placeholder: "https://www.youtube.com/c/YOURPROFIL/",
  },
  {
    title: "Twitter",
    name: "twitter",
    placeholder: "https://twitter.com/YOURPROFIL",
  },
  {
    title: "Tiktok",
    name: "tiktok",
    placeholder: "https://tiktok.com/@YOURPROFIL",
  },
  {
    title: "Soundcloud",
    name: "soundcloud",
    placeholder: "https://soundcloud.com/YOURPROFIL",
  },
  {
    title: "Doctolib",
    name: "doctolib",
    placeholder: "https://doctolib.com/YOURPROFIL",
  },
  {
    title: "Houzz",
    name: "houzz",
    placeholder: "https://houzz.fr/YOURPROFIL",
  },
  {
    title: "UFDI",
    name: "ufdi",
    placeholder: "https://ufdi.fr/YOURPROFIL",
  },
  {
    title: "Côté Maison",
    name: "cotemaison",
    placeholder: "https://projets.cotemaison.fr/YOURPROFIL",
  },
  {
    title: "Skype",
    name: "skype",
    placeholder: "https://www.skype.com/",
  },
  {
    title: "Calendly",
    name: "calendly",
    placeholder: "https://calendly.com/",
  },
  {
    title: "Pinterest",
    name: "pinterest",
    placeholder: "https://pinterest.fr/",
  },
];
