import { Colors } from "types";
import { useEffect, useState } from "react";
import transparentProfilePicture from "../../assets/transparent_profile_picture.png";

type Props = {
  firstname: string;
  lastname: string;
  job: string;
  company: string;
  imgUrl: string | undefined;

  colors: Colors;
};

function BusinessCard({
  firstname,
  lastname,
  job,
  company,
  imgUrl,

  colors,
}: Props) {
  const [lastnameFontSize, setLastnameFontSize] = useState("35px");
  const [firstnameFontSize, setFirstnameFontSize] = useState("35px");

  if (imgUrl === "" || imgUrl === undefined) {
    imgUrl = transparentProfilePicture;
  }

  const changeFirstnameFontSize = (
    textLength: number,
    setFontSize: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (textLength < 6) {
      textLength = 6;
    }
    const baseLenght = 12;
    const mult = baseLenght / (textLength / 1.1);

    // eslint-disable-next-line no-restricted-globals
    if (screen.width <= 768) {
      setFontSize(`${4 * mult}vw`);
    } else {
      setFontSize("30px");
    }
  };

  const changeLastnameFontSize = (
    textLength: number,
    setFontSize: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const baseSize = 6;
    let moveSize = 0;
    if (textLength >= baseSize) {
      moveSize = textLength - baseSize;
    }

    const fontSize = baseSize - Math.log(moveSize * 3);

    // eslint-disable-next-line no-restricted-globals
    if (screen.width <= 768) {
      setFontSize(`${fontSize}vw`);
    } else {
      setFontSize(`25px`);
    }
  };

  useEffect(() => {
    changeLastnameFontSize(lastname.length, setLastnameFontSize);
    changeFirstnameFontSize(firstname.length, setFirstnameFontSize);
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className={"businesscard-main-layout "}
      style={{ backgroundColor: colors.background }}
    >
      <img src={imgUrl} className="businesscard-left-layout" alt="logo"></img>
      <div className="businesscard-right-layout">
        <div
          className="businesscard-right-layout-name "
          style={{ color: colors.fontcolor }}
        >
          <div style={{ fontSize: firstnameFontSize }}>{firstname}</div>

          <div style={{ fontSize: lastnameFontSize }}>
            {lastname.toUpperCase()}
          </div>
        </div>
        <div
          className="businesscard-right-layout-infos mt-1"
          style={{ color: colors.subfontcolor }}
        >
          <div>{job}</div>
          <div>{company} </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCard;
