import { Card } from "types";
import DoctolibIcon from "../../assets/social_network/doctolib_icon.svg";
import FacebookIcon from "../../assets/social_network/facebook_icon.svg";
import HouzzIcon from "../../assets/social_network/houzz_icon.svg";
import InstagramIcon from "../../assets/social_network/instagram_icon.svg";
import LinkedinIcon from "../../assets/social_network/linkedin_icon.svg";
import PinterestIcon from "../../assets/social_network/pinterest_icon.svg";
import SkypeIcon from "../../assets/social_network/skype_icon.svg";
import SoundcloudIcon from "../../assets/social_network/soundcloud_icon.svg";
import TiktokIcon from "../../assets/social_network/tiktok_icon.svg";
import TwitterIcon from "../../assets/social_network/twitter_icon.svg";
import UfdiIcon from "../../assets/social_network/ufdi_icon.svg";
import YoutubeIcon from "../../assets/social_network/youtube_icon.svg";
import CalendlyIcon from "../../assets/social_network/calendly_icon.svg";
import SocialButton from "./SocialButton";

type Props = {
  card: Card;
};

const AvalaibleSocialNetworks: string[][] = [
  ["facebook", FacebookIcon],
  ["houzz", HouzzIcon],
  ["instagram", InstagramIcon],
  ["linkedin", LinkedinIcon],
  ["pinterest", PinterestIcon],
  ["skype", SkypeIcon],
  ["soundcloud", SoundcloudIcon],
  ["tiktok", TiktokIcon],
  ["twitter", TwitterIcon],
  ["ufdi", UfdiIcon],
  ["youtube", YoutubeIcon],
  ["calendly", CalendlyIcon],
  ["doctolib", DoctolibIcon],
];

function SocialButtons({ card }: Props) {
  let buttonsList: React.ReactElement[] = [];

  AvalaibleSocialNetworks.forEach((social: string[]) => {
    let s = social[0] as keyof Card;

    if (social[0] in card && card[s] !== "") {
      buttonsList.push(
        <SocialButton
          socialNetwork={social[1]}
          alt={social[0]}
          colors={card.colors?.socialbutton!}
        />
      );
    }
  });

  if (buttonsList.length !== 0) {
    return (
      <div className="flex flex-wrap justify-center mt-5">{buttonsList}</div>
    );
  }
  return <></>;
}

export default SocialButtons;
