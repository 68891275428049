import { useField } from "formik";
import OutsideAlerter from "hooks/useOutsideAlerter";
import { useState } from "react";
import { ChromePicker } from "react-color";
import useClickOutsideFunction from "hooks/useClickOutside";

export const MyTextInput2 = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col form-input-container">
      <div htmlFor={props.id || props.name} className="form-input-label">
        {label}
      </div>
      <OutsideAlerter>
        <input className="form-input" {...field} type="input" {...props} />
      </OutsideAlerter>
      {meta.error ? (
        <span className="form-input-error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div class="form-control w-full max-w-xs">
      <label class="label">
        <span class="label-text">{label}</span>
        <span class="label-text-alt">{props.rightLabel}</span>
      </label>
      <input
        type="input"
        placeholder={props.placeholder}
        class={`input input-bordered w-full max-w-xs ${
          meta.error ? "input-error" : ""
        }`}
        {...field}
        data-cy={props["data-cy"]}
      />
      <label class="label">
        <span class="label-text-alt text-error">{meta.error}</span>
      </label>
    </div>
  );
};

export const MyPasswordTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [displayPassword, setDisplayPassword] = useState(false);
  console.log(displayPassword);

  const handleDisplayPassword = () => {
    setDisplayPassword(!displayPassword);
  };
  return (
    <div class="form-control w-full max-w-xs">
      <label class="label">
        <span class="label-text">{label}</span>
        <span class="label-text-alt">{props.rightLabel}</span>
      </label>
      <div class="input-group">
        <input
          type={displayPassword ? "text" : "password"}
          placeholder={props.placeholder}
          className={`input input-bordered w-full max-w-xs ${
            meta.error ? "input-error" : ""
          }`}
          {...field}
          data-cy={props["data-cy"]}
        />

        <label className="swap btn btn-square rounded-r-lg btn-primary ">
          <input type="checkbox" onClick={handleDisplayPassword} />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 swap-on "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 swap-off "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
            />
          </svg>
        </label>
      </div>
      <label className="label">
        <span className="label-text-alt text-error">{meta.error}</span>
      </label>
    </div>
  );
};

export const MyFixedTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col ">
      <div htmlFor={props.id || props.name} className="form-input-label">
        {label}
      </div>
      <div className="flex flex-row relative">
        <span className="absolute top-2 left-2">@</span>
        <OutsideAlerter>
          <input className="form-input" {...field} type="input" {...props} />
        </OutsideAlerter>
      </div>
      {meta.error ? (
        <span className="form-input-error">{meta.error}</span>
      ) : null}
    </div>
  );
};

export const MyColorInput = ({ label, ...props }) => {
  const [field, , helpers] = useField(props);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const outside = useClickOutsideFunction(() => {
    setColorPickerOpen(false);
  });

  return (
    <div className="color-input-container" ref={outside}>
      <div className="color-element-container">
        <span
          style={{ color: "white", fontWeight: "bold", fontFamily: "cursive" }}
        >
          {label}
        </span>
        <div
          className="form-color-element"
          style={{
            backgroundColor: field.value,
          }}
          onClick={() => setColorPickerOpen(!colorPickerOpen)}
        ></div>
      </div>
      {colorPickerOpen && (
        <ChromePicker
          color={field.value}
          onChange={({ hex }) => helpers.setValue(hex)}
          disableAlpha
        />
      )}
    </div>
  );
};

export const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="form__error">{meta.error}</div>
      ) : null}
    </div>
  );
};
