import TemplateForm from "components/template/TemplateForm";

function NewTemplate() {
  return (
    <>
      <TemplateForm />
    </>
  );
}

export default NewTemplate;
