import {
  getFirestore,
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  arrayRemove,
  query,
  collection,
  writeBatch,
  where,
  getDocs,
} from "firebase/firestore";
import { Card, Logo, Owner, Template } from "types";
import { deleteStorageDoc, getImageUrl } from "./storageHelper";

const db = getFirestore();

/**
 *
 * @param ownerid id of the owner
 * @returns DocumentSnapshot
 */
export const getOwner = async (ownerid: string) => {
  const ownerRef = doc(db, `owners/${ownerid}`);
  const ownerSnapshot = await getDoc(ownerRef);
  return ownerSnapshot;
};

/**
 *
 * @param templateid id of the template
 * @returns DocumentSnapshot
 */
export const getTemplate = async (
  templateid: string
): Promise<(Template & Logo) | undefined> => {
  const templateRef = doc(db, `templates/${templateid}`);
  const templateSnapshot = await getDoc(templateRef);

  if (!templateSnapshot.exists()) {
    return undefined;
  }

  const templateData = templateSnapshot.data() as Template;
  let logoUrl = "";
  if (templateData.logo) {
    logoUrl = await getImageUrl(templateData.companyid, templateid, "jpg");
  }
  return { ...templateData, id: templateid, logoUrl };
};

/**
 *
 * @param templateid id of the card
 * @returns DocumentSnapshot
 */
export const deleteTemplate = async (templateid: string) => {
  const template = await getTemplate(templateid);
  if (template === undefined) {
    return;
  }
  const { companyid, logo } = template;
  // Update all cards with this template
  const batch = writeBatch(db);

  const cardsRef = collection(db, `cards`);
  const cardsQuery = query(cardsRef, where("templateid", "==", templateid));
  const cardsSnapshot = await getDocs(cardsQuery);
  if (!cardsSnapshot.empty) {
    cardsSnapshot.forEach((elmt) => {
      const cardRef = doc(db, `cards/${elmt.id}`);
      batch.update(cardRef, { templateid: "skyGrey" });
    });
    await batch.commit();
  }

  // Remove img
  if (logo) {
    const imgType = "jpg";
    await deleteStorageDoc(companyid, templateid, imgType);
  }

  // Delete card
  const templateRef = doc(db, `templates/${templateid}`);
  await deleteDoc(templateRef);
};

/**
 *
 * @param cardid id of the card
 * @returns DocumentSnapshot
 */
export const getCard = async (cardid: string) => {
  const cardRef = doc(db, `cards/${cardid}`);
  const cardSnapshot = await getDoc(cardRef);
  return cardSnapshot;
};

/**
 *
 * @param cardid id of the card
 * @returns DocumentSnapshot
 */
export const deleteCard = async (cardid: string) => {
  window.analytics.track("Profile deleted", {
    cardid: cardid,
  });
  const cardSnapshot = await getCard(cardid);
  const cardData = cardSnapshot.data() as Card;
  if (!cardSnapshot.exists() || !cardData.owner?.id) {
    return;
  }
  const userid = cardData.owner?.id;
  // Remove card from user

  const userRef = doc(db, `owners/${userid}`);
  await updateDoc(userRef, { cards: arrayRemove(cardid) });

  // Remove img / vcf
  const imgType = cardData.imgType;
  const img = cardData.imageUrl;

  if (img !== "" || img === undefined) {
    await deleteStorageDoc(userid, cardid, imgType);
  }
  await deleteStorageDoc(userid, cardid, "vcf");

  // Delete card
  const cardRef = doc(db, `cards/${cardid}`);
  await deleteDoc(cardRef);
};

/**
 * Activate the card and disable the activated card
 * @param userid ID of the Owner
 * @param cardid ID of the card to activate
 */
export const activeCard = async (userid: string, cardid: string) => {
  window.analytics.track("Profile activated", {
    cardid: cardid,
  });
  const ownerSnapshot = await getOwner(userid);
  const ownerData = ownerSnapshot.data() as Owner;
  if (ownerData.active) {
    const activeCardRef = doc(db, `cards/${ownerData.active}`);
    await updateDoc(activeCardRef, { active: false });
  }

  const ownerRef = doc(db, `owners/${userid}`);
  await updateDoc(ownerRef, { active: cardid });

  const cardRef = doc(db, `cards/${cardid}`);
  await updateDoc(cardRef, { active: true });
};
