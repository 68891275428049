import Cropper from "react-easy-crop";
import React, { useState, useCallback } from "react";
import getCroppedImg from "helpers/cropImage";
import { Area, Point } from "react-easy-crop/types";
import { useTranslation } from "react-i18next";

interface CropImageProps {
  imageUrl: string;
  aspect: number;
  setCroppedImage: React.Dispatch<
    React.SetStateAction<{
      blobUrl: string;
      base64: string;
      type: string;
    }>
  >;
}
/**
 *
 * @param {string} imageUrl  Url of the image to crop
 * @param {string} setCroppedImage Set to take CropImage (blobUrl: string, base64: string, type: string)
 * @returns
 */
export const CropImage = ({
  imageUrl,
  setCroppedImage,
  aspect,
}: CropImageProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.form.crop_image",
  });

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [preview, setPreview] = useState<string>("");
  const [isCropping, setIsCropping] = useState<boolean>(true);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    if (croppedAreaPixels) {
      const { blobUrl, base64 } = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
        aspect
      );
      setCroppedImage({ blobUrl, base64, type: "image/jpeg" });
      setIsCropping(false);
      setPreview(blobUrl);
    }
  };

  return (
    <>
      {isCropping ? (
        <>
          <div className="cropimage-container">
            <div className="cropimage-image-container">
              <Cropper
                image={imageUrl}
                minZoom={0.3}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                restrictPosition={false}
                // style={{mediaStyle: {width: 100}}}
              />
            </div>
          </div>

          <div className="cropimage-option-container">
            <div className="cropimage-range-container">
              <span>{t("zoom")} </span>
              <input
                type="range"
                min="0.3"
                max="4"
                step="0.05"
                value={zoom}
                name="zoom"
                onChange={(e) => setZoom(e.target.valueAsNumber)}
              />
            </div>

            <button
              onClick={showCroppedImage}
              type="button"
              className="button-form"
            >
              {t("validate")}
            </button>
          </div>
        </>
      ) : (
        <div className="cropimage-preview-container">
          <button
            onClick={() => setIsCropping(true)}
            type="button"
            className="button-form"
          >
            {t("redimension")}
          </button>
          {preview !== "" ? (
            <>
              <img
                src={preview}
                width={180}
                height={30}
                alt="preview crop"
                className="cropimage-preview"
              />
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
