import React, {useContext} from "react";
import {Route, Redirect} from "react-router-dom";
import {AuthContext} from "AuthProvider";
import Loading from "components/Loading";

const PrivateRoute = ({component: RouteComponent, ...rest}: any) => {
  const authContext = useContext(AuthContext);
  if (authContext?.loadingAuthState) {
    return <Loading />;
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        authContext?.user ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{pathname: "/auth/login", state: {prevPath: rest.path}}}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
