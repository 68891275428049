import * as React from "react";
import { IoMdAddCircleOutline } from "react-icons/io";

/**
 * This component is used to redirect to the "New Profile" form.
 */

interface IAddNewProfile {
  onClick: Function;
}

const AddNewProfile = ({ onClick }: IAddNewProfile) => {
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className="relative group h-[220px] w-[350px] bg-[#1a675d] rounded-[35px] transition ease-in-out delay-100 hover:rotate-6 hover:cursor-pointer"
    >
      <div className="absolute flex flex-col items-center justify-center h-full w-full border-2 border-gray-400 rounded-[35px] bg-white rotate-6 transition ease-in-out delay-100 group-hover:-rotate-6">
        <IoMdAddCircleOutline
          className="-rotate-6 transition ease-in-out delay-100 group-hover:rotate-0 group-hover:scale-105"
          color="#1a675d"
          size={70}
        />
      </div>
    </div>
  );
};

export default AddNewProfile;
