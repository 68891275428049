import InstagramButton from "./InstagramButton";
import LinkedinButton from "./LinkedinButton";
import SoundcloudButton from "./SoundcloudButton";

import { Colors } from "types";

type Props = {
  colors: Colors;
};

/**
 * Takes the url and the OS to display the social button
 * @param url url of the profile
 * @param mobileOS OS of the device for handling deeplink
 * @returns Social Button
 */

function SocialGrid({ colors }: Props) {
  return (
    <div className=" social-buttons-position   ">
      <InstagramButton colors={colors} />
      <LinkedinButton colors={colors} />
      <SoundcloudButton colors={colors} />
    </div>
  );
}

export default SocialGrid;
