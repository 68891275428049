import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Loading from "components/Loading";
import BusinessCard from "components/BusinessProfile/BusinessCard";
import { Logo, Template } from "types";
import { deleteTemplate, getTemplate } from "helpers/firestoreHelper";
import { avatars } from "components/template/avatars";
import { useTranslation } from "react-i18next";

interface IParams {
  templateid: string;
}

function TemplateInfo() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.template.template_info",
  });
  const { templateid } = useParams<IParams>();

  const [template, setTemplate] = useState<Template & Logo>();
  const [isLoading, setIsLoading] = useState(true);
  const avatar = avatars[Math.floor(Math.random() * avatars.length)];

  const history = useHistory();

  const handleDelete = async () => {
    const result = window.confirm(
      "Supprimer le template ? \nAttention, la suppression est définitive et le template de tous les profils ayant ce template sera remplacé par Sky Grey"
    );
    if (result) {
      setIsLoading(true);
      await deleteTemplate(templateid);
      setIsLoading(false);
      history.replace(`/dashboard/templates`);
    }
  };

  const handleModify = () => {
    history.push(`${templateid}/edit`);
  };

  useEffect(() => {
    const queryTemplateInfoFromDB = async () => {
      const templateTemp = await getTemplate(templateid);

      if (templateTemp === undefined) {
        console.log("No matching documents.");
        return;
      }
      setTemplate(templateTemp);
    };
    queryTemplateInfoFromDB();
    setIsLoading(false);
    //eslint-disable-next-line
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      {isLoading || template === undefined ? (
        <Loading />
      ) : (
        <>
          <div className="cardinfo-actionbar">
            <button onClick={handleGoBack} className="cardinfo-button-back">
              {t("back")}
            </button>

            <div>
              <button onClick={handleModify} className="cardinfo-button-edit">
                {t("edit")}
              </button>
            </div>
            <button onClick={handleDelete} className="cardinfo-button-delete">
              {t("delete")}
            </button>
          </div>

          <div className="cardinfo-container">
            <div className="cardinfo-businesscard">
              <BusinessCard
                colors={template.colors.businesscard}
                company={template.name}
                firstname={t("firstname")}
                lastname={t("lastname")}
                imgUrl={avatar}
                job={t("job")}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TemplateInfo;
