import TemplateForm from "components/template/TemplateForm";

function EditTemplate() {
  return (
    <>
      <TemplateForm />
    </>
  );
}

export default EditTemplate;
