import YoutubeEmbed from "components/help/YoutubeEmbed";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdExpandMore } from "react-icons/md";
function Help() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.help.help",
  });
  useEffect(() => {
    window.analytics.page("Help");
  }, []);

  return (
    <div className="faq">
      <div className="global-label">
        <div className="global-label-text">{t("title")}</div>
      </div>
      <FaqContainer title={t("question_1")} embedId="Che-obtz8FI">
        <p></p>
      </FaqContainer>
      <br />
      <FaqContainer title={t("question_2")} embedId="fABRngg7IGE">
        <p>{t("answer_2_description")}</p>
        <ol>
          <li>{t("answer_2_step_1")}</li>
          <li>{t("answer_2_step_2")}</li>
          <li>{t("answer_2_step_3")}</li>
          <li>{t("answer_2_step_4")}</li>
          <li>{t("answer_2_step_5")}</li>
          <li>{t("answer_2_step_6")}</li>
          <li>{t("answer_2_step_7")}</li>
        </ol>
      </FaqContainer>
    </div>
  );
}

type FaqContainerProps = {
  title: string;

  embedId?: string;
  children: React.ReactNode;
};

const FaqContainer = ({
  title,

  embedId = "",
  children,
}: FaqContainerProps) => {
  const [answerIsOpen, setAnswerIsOpen] = useState(false);
  return (
    <div className="faq-container">
      <div className="faq-label" onClick={() => setAnswerIsOpen((c) => !c)}>
        <div className="faq-label-text">{title}</div>
        <div className="faq-label-icon">
          <MdExpandMore size="28" color="white" />
        </div>
      </div>
      {answerIsOpen && (
        <div className="faq-answer ">
          <div className="faq-answer-content"> {children}</div>
          {embedId !== "" && (
            <>
              <p>Vous pouvez retrouver ci-dessous une vidéo explicative.</p>
              <YoutubeEmbed embedId={embedId} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Help;
