import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { MyTextInput } from "components/form/FormComponents";
import vCards from "vcards-js";
import { ImageListType } from "react-images-uploading";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
} from "firebase/storage";
import { Card, CroppedImage, Owner } from "types";
import { IBase64Image } from "pages/UserInfo";
import { validationSchema } from "./schemas/EditProfileValidationSchema";
import { activeCard, getCard, getOwner } from "helpers/firestoreHelper";
import { deleteStorageDoc, getImageUrl } from "helpers/storageHelper";
import { imageUrlToBase64 } from "helpers/imageHelper";
import { ImageUpload } from "components/ImageUpload";
import AddressInput from "./AddressInput";
import { randomString } from "helpers/functionsHelper";
import { CropImage } from "./CropImage";
import { socialNetworks } from "helpers/socialNetworks";
import { emptyCard } from "helpers/fakeData";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface IParams {
  userid: string;
  cardid?: string;
}
type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type IInitialValues = Override<
  Card,
  {
    cv: File | undefined;
  }
>;

function CardForm() {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.form.card_form",
  });

  const [images, setImages] = useState<ImageListType>([]);
  const [croppedImage, setCroppedImage] = useState<CroppedImage>({
    blobUrl: "",
    base64: "",
    type: "",
  });
  const [owner, setOwner] = useState<Owner>();

  const [initialValues, setInitialValues] = useState<IInitialValues>({
    ...emptyCard,

    cv: undefined,
  });
  const onChange = (imageList: any) => {
    // data for submit
    setImages(imageList);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [base64Image, setBase64Image] = useState<IBase64Image>();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { userid, cardid } = useParams<IParams>();

  var temp = history.location.pathname.split("/");
  const editing = !(temp[temp.length - 1] === "new");

  useEffect(() => {
    (async () => {
      const ownerSnapshot = await getOwner(userid);

      if (!ownerSnapshot.exists()) {
        console.log("No matching documents.");
        return;
      }

      const { firstname, lastname } = ownerSnapshot.data();
      setInitialValues((c) => ({ ...c, firstname, lastname }));
      setOwner({ id: userid, firstname, lastname });

      if (editing && cardid !== undefined) {
        const cardSnapshot = await getCard(cardid);

        if (!cardSnapshot.exists()) {
          console.log("No matching documents.");
          return;
        }

        const cs = cardSnapshot.data() as Card;

        const imageUrl = await getImageUrl(userid, cardid, cs.imgType);

        await imageUrlToBase64(imageUrl, setBase64Image);

        setImgUrl(imageUrl);

        setInitialValues({
          id: cardid,
          ...cs,

          cv: undefined,
        });
      }

      setIsLoading(false);
    })();
    //eslint-disable-next-line
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="cardform-actionbar">
            <button
              className="btn btn-primary btn-sm ml-2"
              onClick={handleGoBack}
            >
              {t("back")}
            </button>
          </div>
          <div className="cardform-title-container">
            <h1 className="cardform-title">
              {editing ? t("title_edit") : t("title_create")} {owner?.firstname}{" "}
              {owner?.lastname}
            </h1>
            <h3 className="text-center">{t("description")}</h3>
            <br></br>
            <h4 className="text-center">{t("tips")}</h4>
          </div>
          <div className="cardform-image-container">
            {imgUrl ? <img src={imgUrl} alt="logo" width={300} /> : null}
          </div>
          <ImageUpload value={images} onChange={onChange} />
          {images[0] ? (
            <CropImage
              imageUrl={images[0] ? images[0].data_url : null}
              setCroppedImage={setCroppedImage}
              aspect={3 / 4}
            />
          ) : null}
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const db = getFirestore();
              // Check if image
              if (!editing && croppedImage.blobUrl === "") {
                toast.error(t("error_no_image"));
                return;
              }
              if (
                editing &&
                images[0] !== undefined &&
                croppedImage.blobUrl === ""
              ) {
                toast.error(t("error_validation_missing"));
                return;
              }
              let type;

              if (editing && images.length === 0) {
                type = initialValues.imgType;
              } else {
                if (images[0].file) {
                  type = images[0].file.type.split("/")[1];
                }
              }

              setIsSubmitting(true);

              var ID = "_" + randomString(6);
              let cardId;
              editing ? (cardId = initialValues.id) : (cardId = userid + ID);

              const storage = getStorage();
              const imageRef = ref(storage, `${userid}/${cardId}.${type}`);

              if (editing && images.length !== 0) {
                const prevImageRef = ref(
                  storage,
                  `${userid}/${cardId}.${initialValues.imgType}`
                );
                await deleteObject(prevImageRef);
              }
              if (images[0]?.file) {
                let blob = await fetch(croppedImage.blobUrl)
                  .then((r) => r.blob())
                  .then(
                    (blobFile) =>
                      new File([blobFile], "Hey", {
                        type: "image/jpeg",
                      })
                  );
                await uploadBytes(imageRef, blob);
              }

              // Création de la vCard

              const handleVCardCreation = async (values: IInitialValues) => {
                const {
                  firstname,
                  lastname,
                  company,
                  job,
                  prophone,
                  personalphone,
                  officephone,
                  proemail,
                  personalemail,

                  proaddress,

                  otheraddress,

                  mainwebsite,
                  secondwebsite,
                } = values;
                var vCard = vCards();
                // image
                if (editing) {
                  if (images[0]) {
                    const { base64, type } = croppedImage;
                    vCard.photo.embedFromString(base64, type);
                  } else {
                    if (base64Image) {
                      vCard.photo.embedFromString(
                        base64Image.base64,
                        base64Image.type
                      );
                    }
                  }
                } else {
                  const { base64, type } = croppedImage;
                  vCard.photo.embedFromString(base64, type);
                }
                //set basic properties shown before
                vCard.firstName = firstname;
                vCard.lastName = lastname;
                vCard.organization = company;

                vCard.title = job;
                vCard.url = secondwebsite;
                vCard.workUrl = mainwebsite;
                vCard.note = "";
                // homephone = Domicile | workphone = Bureau | cellPhone = Mobile
                vCard.homePhone = officephone;
                vCard.workPhone = prophone;
                vCard.cellPhone = personalphone;

                vCard.email = personalemail;
                vCard.workEmail = proemail;

                vCard.workAddress = {
                  label: proaddress.label,
                  city: proaddress.city,
                  countryRegion: proaddress.countryregion,
                  postalCode: proaddress.postalcode,

                  street: proaddress.street,
                  stateProvince: "",
                };

                vCard.homeAddress = {
                  label: otheraddress.label,
                  city: otheraddress.city,
                  countryRegion: otheraddress.countryregion,
                  postalCode: otheraddress.postalcode,
                  stateProvince: "",
                  street: otheraddress.street,
                };

                vCard.version = "3.0";

                return vCard.getFormattedString();
              };

              const vCard = await handleVCardCreation(values);
              var vCardFile = new Blob([vCard], {
                type: "text/vcard;charset=utf-8",
              });
              // Upload to firebase storage

              const vcfRef = ref(storage, `${userid}/${cardId}.vcf`);

              const metadata = { contentType: "text/x-vcard" };
              await uploadBytes(vcfRef, vCardFile, metadata);
              let cvDownloadUrl = "";
              if (values?.iscv && values?.cv !== undefined) {
                const cvRef = ref(storage, `${userid}/${cardId}.pdf`);

                await uploadBytes(cvRef, values.cv, {
                  contentType: "application/pdf",
                });
                const cvDownloadUrlPromise = getDownloadURL(cvRef);
                cvDownloadUrl = await cvDownloadUrlPromise;
              }
              if (editing && !values.iscv && initialValues.iscv && cardId) {
                await deleteStorageDoc(userid, cardId, "pdf");
              }

              const imageDownloadUrlPromise = getDownloadURL(imageRef);
              const vcfDownloadUrlPromise = getDownloadURL(vcfRef);
              const [imageDownloadUrl, vcfDownloadUrl] = await Promise.all([
                imageDownloadUrlPromise,
                vcfDownloadUrlPromise,
              ]);

              const cardRef = doc(db, `cards/${cardId}`);

              await setDoc(
                cardRef,
                {
                  ...values,
                  cv: cvDownloadUrl,
                  imageUrl: imageDownloadUrl,
                  vcfUrl: vcfDownloadUrl,

                  imgType: type,

                  website: {
                    active: !(values.mainwebsite === ""),
                    url: values.mainwebsite,
                  },

                  owner,
                },
                { merge: true }
              );
              const userRef = doc(db, `owners/${userid}`);
              if (!editing) {
                await updateDoc(userRef, {
                  cards: arrayUnion(cardId),
                });
                cardId && (await activeCard(userid, cardId));
              }
              toast.success(
                editing ? t("success_card_edited") : t("success_card_created")
              );
              getDoc(userRef).then((elmt) => {
                const { cards } = elmt.data() as { cards: string[] };
                editing
                  ? window.analytics.track("Profile edited", {
                      user: elmt.id,
                      nb_profiles: cards.length,
                    })
                  : window.analytics.track("Profile added", {
                      user: elmt.id,
                      nb_profiles: cards.length,
                    });
              });

              setSubmitting(false);
              setIsSubmitting(false);
              history.replace(`/dashboard/users/${userid}`);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="cardform-input-container ">
                    <MyTextInput
                      label={t("firstname")}
                      name="firstname"
                      type="text"
                      placeholder={owner?.firstname ? owner.firstname : ""}
                    />

                    <MyTextInput
                      label={t("lastname")}
                      name="lastname"
                      type="text"
                      placeholder={owner?.lastname ? owner.lastname : ""}
                    />
                    <MyTextInput
                      label={t("company")}
                      name="company"
                      type="text"
                      placeholder=""
                    />
                    <MyTextInput
                      label={t("job")}
                      name="job"
                      type="text"
                      placeholder=""
                    />
                    <h1 className="cardform-subtitle">Informations contact</h1>
                    <MyTextInput
                      label={t("phone_office")}
                      name="prophone"
                      type="tel"
                      placeholder="+33112345678"
                    />
                    <MyTextInput
                      label={t("phone_mobile")}
                      name="personalphone"
                      type="tel"
                      placeholder="+33612345678"
                    />
                    <MyTextInput
                      label={t("phone_home")}
                      name="officephone"
                      type="tel"
                      placeholder="+33112345678"
                    />
                    <MyTextInput
                      label={t("email_professional")}
                      name="proemail"
                      type="email"
                      placeholder=""
                    />
                    <MyTextInput
                      label={t("email_personal")}
                      name="personalemail"
                      type="email"
                      placeholder=""
                    />

                    <h1 className="cardform-subtitle">
                      Adresse professionnelle
                    </h1>
                    <AddressInput name="proaddress" />
                    <h1 className="cardform-subtitle">Adresse personnelle</h1>
                    <AddressInput name="otheraddress" />

                    <MyTextInput
                      label={t("website")}
                      name="mainwebsite"
                      type="text"
                      placeholder="https://www.justonecard.fr"
                    />
                    <MyTextInput
                      label={t("website_second")}
                      name="secondwebsite"
                      type="text"
                      placeholder=""
                    />

                    <h1 className="cardform-subtitle">{t("social_network")}</h1>

                    {socialNetworks.map(({ title, name, placeholder }) => {
                      return (
                        <>
                          <MyTextInput
                            label={title}
                            id={name}
                            name={name}
                            type="text"
                            placeholder={placeholder}
                          />
                        </>
                      );
                    })}
                    <div className="cardform-cv-container">
                      <span className="cardform-subtitle">
                        {t("document_pdf")}
                      </span>
                      {values?.iscv ? (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            setFieldValue("cv", undefined);
                            setFieldValue("iscv", false);
                          }}
                        >
                          {t("remove_pdf")}
                        </button>
                      ) : (
                        <input
                          className="btn btn-secondary btn-outline"
                          name="cv"
                          type="file"
                          accept=".pdf, application/pdf"
                          onChange={(event) => {
                            const e = event;
                            const currentTarget = e.currentTarget;
                            const files = currentTarget.files;
                            if (files !== null) {
                              setFieldValue("cv", files[0]);
                              setFieldValue("iscv", true);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="cardform-button-submit-container">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {}}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? t("saving") : t("save")}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
}

export default CardForm;
