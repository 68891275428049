import { useTranslation } from "react-i18next";
import ImageUploading, {
  ImageUploadingPropsType,
} from "react-images-uploading";

export const ImageUpload = (props: ImageUploadingPropsType) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.image_upload",
  });
  return (
    <ImageUploading
      value={props.value}
      onChange={props.onChange}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,

        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div className="flex justify-center align-center">
          <button
            onClick={onImageUpload}
            {...dragProps}
            type="button"
            className="btn btn-primary"
          >
            {t("upload_image")}
          </button>
          &nbsp;
        </div>
      )}
    </ImageUploading>
  );
};
