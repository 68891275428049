export const randomString = (size: number) => {
  return Math.random()
    .toString(36)
    .substr(2, size - 1);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Check if an array is empty
 * https://github.com/1milligram/1loc/blob/master/snippets/array/check-if-an-array-is-empty.md
 */
export const isEmpty = <T,>(arr: T[]): boolean =>
  !Array.isArray(arr) || arr.length === 0;

export const translateFirebaseAuthError = (error: string) => {
  switch (error) {
    case "auth/too-many-requests":
      return "Vous avez effectué trop de requêtes récemment, merci de réessayer plus tard.";
    case "auth/invalid-email":
      return "Le format de l'email est incorrect";
    case "auth/user-not-found":
      return "L'utilisateur n'existe pas";
    default:
      return "L'email ou le mot de passe est incorrect";
  }
};

interface IHandleSaveQrCOde {
  filename: string;
}
export const handleSaveQrCode = ({ filename }: IHandleSaveQrCOde) => {
  window.analytics.track("Qrcode saved");
  const canvas = document.getElementById("qrcode") as HTMLCanvasElement;

  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = `${filename}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

interface IRemovePhotoFromVcfString {
  vcf: string;
}

export const removePhotoFromVcfString = ({
  vcf,
}: IRemovePhotoFromVcfString) => {
  const [beforePhoto, afterPhoto] = vcf.split("PHOTO");
  const [, ...rest] = afterPhoto.split("\n");
  const vcfWithoutPhoto = beforePhoto + rest.join("\n");
  return vcfWithoutPhoto;
};
