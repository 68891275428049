import { Colors } from "types";

type Props = {
  socialNetwork: string;

  alt: string;

  colors: Colors;
};

/**
 * Takes the url and the OS to display the social button
 * @param socialNetwork Which social network
 * @param url url of the profile
 * @param alt Text to display if low connection
 * @param mobileOS OS of the device for handling deeplink
 * @param deeplink Deeplink of the social network
 * @returns Social Button
 */

function SocialButton({ socialNetwork, alt, colors }: Props) {
  return (
    <>
      <div
        className="social-button-layout"
        style={{ backgroundColor: colors.background }}
      >
        <div className="social-logo-container">
          <img src={socialNetwork} alt={alt} className="social-logo" />
        </div>
      </div>
    </>
  );
}

export default SocialButton;
