import CardForm from "components/form/CardForm";
import React, { useEffect } from "react";

function NewCard() {
  useEffect(() => {
    window.analytics.page("Profile new");
  }, []);
  return <CardForm />;
}

export default NewCard;
