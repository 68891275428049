import { ColorSet } from "types";

import BusinessCard from "components/BusinessProfile/BusinessCard";
import DefaultAvatar from "assets/avatars/avatar0.png";
import InfosPanel from "components/BusinessProfile/InfosPanel";
import Instagram from "../../assets/social_network/instagram_icon.svg";
import Linkedin from "../../assets/social_network/linkedin_icon.svg";
import SoundCloud from "../../assets/social_network/soundcloud_icon.svg";
import SocialButton from "components/BusinessProfile/SocialButton";
import ContactButtons from "components/BusinessProfile/ContactButtons";

interface IDefaultPreviewTemplate {
  colors: ColorSet;
  mouseCoordinates: { x: number; y: number };
}

interface IDefaultPreviewTemplate {
  colors: ColorSet;
  mouseCoordinates: { x: number; y: number };
}

/**
 * DefaultPreviewTemplate is the default template for the preview of the business card
 * @param colors Color Set containing the colors of the Card
 * @param mouseCoordinates Dictionary containing the coordinates of the mouse on the screen: {x: coord.x(int), y: coord.y(int)}
 * @returns The default template for the preview of the business card
 */
function DefaultPreviewTemplate({
  colors,
  mouseCoordinates,
}: IDefaultPreviewTemplate) {
  return (
    <div
      style={{
        top: mouseCoordinates.y - 300 + "px",
        left: mouseCoordinates.x + 100 + "px",
      }}
      className="default-preview-template fade-in"
    >
      {/* Header containing the user info card */}
      <div className="cardinfo-container">
        <BusinessCard
          firstname="Prénom"
          lastname="Nom"
          job="Étudiant"
          company="JustOneCard"
          imgUrl={DefaultAvatar}
          colors={colors.businesscard}
        />
      </div>

      {/* Div containing social media buttons */}
      <div className="social-grid-container mt-3">
        <SocialButton
          socialNetwork={Instagram}
          alt="instagram"
          colors={colors.socialbutton}
        />
        <SocialButton
          socialNetwork={Linkedin}
          alt="instagram"
          colors={colors.socialbutton}
        />
        <SocialButton
          socialNetwork={SoundCloud}
          alt="instagram"
          colors={colors.socialbutton}
        />
      </div>

      {/* Div containing buttons to share user information */}
      <ContactButtons colors={colors} />

      {/* Div containing the user's contact information */}
      <div>
        <InfosPanel
          phone="+33 6 87 36 26 73"
          mail="jean.dupont@gmail.com"
          website="https://www.google.fr"
          colors={colors.infospanel}
        />
      </div>
    </div>
  );
}

export default DefaultPreviewTemplate;
