import { useContext, useEffect, useState, MouseEvent } from "react";
import { useHistory, useParams } from "react-router";
import Loading from "components/Loading";
import PermissionError from "components/PermissionError";
import BusinessCard from "components/BusinessProfile/BusinessCard";
import { Card, ColorSet, Logo, Template } from "types";
import {
  activeCard,
  deleteCard,
  getCard,
  getTemplate,
} from "helpers/firestoreHelper";
import {
  AllColors,
  blueClassic,
  coffee,
  ColorSetName,
  dark,
  sepia,
  skyGrey,
  strawberry,
} from "components/palette";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { AuthContext } from "AuthProvider";
import toast from "react-hot-toast";
import { useMobileMediaQuery } from "helpers/responsiveHelper";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import DefaultPreviewTemplate from "components/template/DefaultPreviewTemplate";
import CurrentUserCard from "components/template/CurrentUserCard";
import { QRCodeCanvas } from "qrcode.react";
import {
  handleSaveQrCode,
  removePhotoFromVcfString,
} from "helpers/functionsHelper";

interface IParams {
  userid: string;
  cardid: string;
}

interface IPreviewTemplate {
  isVisible: boolean;
  color: undefined | ColorSet;
}

function CardInfo() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.card_info",
  });
  const { cardid, userid } = useParams<IParams>();

  const isMobile = useMobileMediaQuery();
  const [previewTemplate, setPreviewTemplate] = useState<IPreviewTemplate>({
    isVisible: false,
    color: undefined,
  });
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

  const [card, setCard] = useState<Card>();
  const [activated, setActivated] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorPermissions, setErrorPermissions] = useState(false);
  const [templates, setTemplates] = useState<(Template & Logo)[]>([]);
  const [templateTemp, setTemplateTemp] = useState("");
  const [colorChanging, setColorChanging] = useState(false);
  const [vcfWithoutPhoto, setVcfWithoutPhoto] = useState("");
  const [offlineQrcodeVisible, setOfflineQrcodeVisible] = useState(false);
  let conditionalRendering = <Loading />;

  const db = getFirestore();
  const colorSets: AllColors = {
    blueClassic: blueClassic,
    coffee: coffee,
    dark: dark,
    sepia: sepia,
    skyGrey: skyGrey,
    strawberry: strawberry,
  };
  let companyid = "";
  const authContext = useContext(AuthContext);
  if (authContext) {
    companyid = authContext.companyid;
  }

  const history = useHistory();

  const handleDelete = async () => {
    if (card && card.active === true) {
      toast.error(t("delete_error"));
      return;
    }
    const result = window.confirm(t("delete_confirmation"));

    if (result) {
      setIsLoading(true);
      await deleteCard(cardid);
      setIsLoading(false);
      history.replace(`/dashboard/users/${userid}`);
    }
  };

  const handleModify = () => {
    history.push(`${cardid}/edit`);
  };

  useEffect(() => {
    window.analytics.page("Profile infos");
  }, []);

  useEffect(() => {
    const getTemplates = async () => {
      const storage = getStorage();
      const templateRef = collection(db, "templates");
      const templateQuery = query(
        templateRef,
        where("companyid", "==", companyid)
      );
      const templateSnapshot = await getDocs(templateQuery);

      templateSnapshot.forEach(async (elmt) => {
        const elt = elmt.data() as Template & Logo;
        let logoUrl = "";
        if (elt.logo) {
          const logoRef = ref(storage, `${companyid}/${elmt.id}.jpg`);
          logoUrl = await getDownloadURL(logoRef);
        }

        setTemplates((c) => [
          ...c,
          { ...elt, id: elmt.id, logoUrl: elt.logo ? logoUrl : "" },
        ]);
      });
    };
    const queryCardInfoFromDB = async () => {
      try {
        const cardSnapshot = await getCard(cardid);

        if (!cardSnapshot.exists()) {
          setErrorPermissions(true);
          return;
        }

        const cardData = cardSnapshot.data() as Card;

        setCard({ id: cardSnapshot.id, ...cardData });

        // Fetch vcf from URL
        const vcfFetched = await fetch(cardData.vcfUrl);
        // Convert vcf to text
        const vcfText = await vcfFetched.text();
        // Remove photo from vcf because it is to heavy to be displayed
        const vcf = removePhotoFromVcfString({ vcf: vcfText });

        setVcfWithoutPhoto(vcf);
        if (cardData.templateid) {
          let template = await getTemplate(cardData.templateid);

          setCard((c) =>
            c
              ? { ...c, colors: template ? template.colors : skyGrey }
              : undefined
          );
        }
        setActivated(cardData.active);
      } catch (error: any) {
        if (error.code === "permission-denied") {
          setErrorPermissions(true);
        }
        return;
      }
    };
    getTemplates();
    queryCardInfoFromDB();
    setIsLoading(false);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    card?.templateid && setTemplateTemp(card?.templateid);
  }, [card]);

  const handleActivation = async () => {
    await activeCard(userid, cardid);
    toast.success(t("active_success"));
    setActivated(true);
  };

  const handleGoBack = () => {
    history.goBack();
  };
  const handleSubmit = async (e: any) => {
    console.log(e);
    let template = templateTemp;

    if (!templates.some((t) => t.id === templateTemp)) {
      template = templates[0].id;
    }
    if (!card) {
      return;
    }
    setColorChanging(true);
    const colors = templates.find((elmt) => elmt.id === template)?.colors;
    const cardRef = doc(db, `cards/${cardid}`);
    await updateDoc(cardRef, { templateid: template, colors: colors });

    setCard({ ...card, templateid: template, colors });
    toast.success(t("submit_success"));
    setColorChanging(false);
  };
  const changeTemplate = async (colorset: ColorSetName) => {
    if (!card) {
      return;
    }
    if ("") setColorChanging(true);
    const colors = colorSets[colorset];
    const cardRef = doc(db, `cards/${cardid}`);
    await updateDoc(cardRef, { templateid: colorset, colors: colors });

    setCard({ ...card, templateid: colorset, colors });
    toast.success(t("submit_success"));
    setColorChanging(false);
  };

  const navigateToTemplate = () => {
    window.analytics.track("button to template clicked");
    history.push(`/dashboard/templates`);
  };

  const backButtonText = useMobileMediaQuery() ? "<" : t("back");
  const deleteButtonText = useMobileMediaQuery() ? <FaTrash /> : "Supprimer";
  const createTemplateTips = useMobileMediaQuery() ? (
    <i className="text-sm text-center">{t("template_info_mobile")}</i>
  ) : (
    <i className="text-sm">
      {t("template_info_desktop")}{" "}
      <span
        onClick={navigateToTemplate}
        style={{ color: "blue", cursor: "pointer" }}
      >
        {t("here")}
      </span>
    </i>
  );
  if (errorPermissions) conditionalRendering = <PermissionError />;

  /**
   * Function used to get the mouse coodinates relative to the higher parent div
   * @param event
   */
  function handleMouseMove(event: MouseEvent) {
    setMouseCoords({
      x: event.clientX,
      y: event.clientY,
    });
  }

  return (
    <>
      {isLoading ||
      errorPermissions ||
      card === undefined ||
      card.imageUrl === undefined ? (
        conditionalRendering
      ) : (
        <div onMouseMove={handleMouseMove}>
          <div className="cardinfo-actionbar">
            <button onClick={handleGoBack} className="btn btn-primary btn-sm">
              {backButtonText}
            </button>

            <div className="cardinfo-actionbar-center">
              <button onClick={handleModify} className="btn btn-primary btn-sm">
                {t("edit")}
              </button>
              <button
                onClick={handleActivation}
                disabled={activated}
                className="btn btn-accent btn-sm"
              >
                {activated ? t("activated") : t("activate")}
              </button>
            </div>
            <button onClick={handleDelete} className="btn btn-error btn-sm">
              {deleteButtonText}
            </button>
          </div>

          {previewTemplate.isVisible && previewTemplate.color && (
            <DefaultPreviewTemplate
              colors={previewTemplate.color}
              mouseCoordinates={mouseCoords}
            />
          )}

          <div
            className={`flex flex-col md:flex-row md:mx-auto md:w-2/3 justify-around items-center min-h-screen`}
          >
            {isMobile ? (
              <div
                className="w-1/2 mt-4
                     md:flex md:flex-col md:justify-between md:h-[70%] md:w-80 md:mt-0"
                style={
                  activated
                    ? {
                        borderRadius: "35px",
                      }
                    : {}
                }
              >
                <BusinessCard
                  firstname={card.firstname}
                  lastname={card.lastname}
                  job={card.job}
                  company={card.company}
                  imgUrl={card.imageUrl}
                  colors={
                    card.colors
                      ? card.colors.businesscard
                      : blueClassic.businesscard
                  }
                />
              </div>
            ) : (
              <CurrentUserCard card={card} />
            )}
            <div>
              <div className="flex flex-col  items-center">
                {offlineQrcodeVisible ? (
                  <div className="my-4 flex flex-col space-y-2 items-center">
                    <QRCodeCanvas
                      value={vcfWithoutPhoto}
                      size={256}
                      level="H"
                      id="qrcode"
                    />
                    <ul>
                      <li className="text-xs italic">
                        Cette fiche ne contient pas la photo.
                      </li>
                      <li className="text-xs italic">
                        Cela rendrait le qrcode trop lourd.
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleSaveQrCode({
                          filename: `${card.firstname}-${card.lastname}-JustOneCard-offline.png`,
                        });
                      }}
                    >
                      {t("download")}
                    </button>
                  </div>
                ) : (
                  <div
                    className="tooltip"
                    data-tip={t("offline_qrcode_tooltip")}
                  >
                    <button
                      className="btn btn-outline"
                      onClick={() => {
                        setOfflineQrcodeVisible(true);
                      }}
                    >
                      {t("display_qrcode")}
                    </button>
                  </div>
                )}
              </div>
              <span className="cardinfo-template-title">
                {t("template_actual")}{" "}
                <strong>
                  {card?.templateid && card?.templateid.length < 13
                    ? card?.templateid
                    : templates.find((val) => val.id === card?.templateid)
                        ?.name}
                </strong>
              </span>
              <div className="cardinfo-template-title">
                <span>{t("preset_template")}</span>
              </div>
              <div className="cardinfo-template-container">
                <button
                  className=" btn btn-outline
                   hover:bg-blue-500
                     text-blue-700  
                        "
                  onClick={() => {
                    changeTemplate("blueClassic");
                  }}
                  disabled={colorChanging}
                  title="Blue Classic"
                  onMouseEnter={() =>
                    setPreviewTemplate({
                      isVisible: true,
                      color: colorSets.blueClassic,
                    })
                  }
                  onMouseLeave={() =>
                    setPreviewTemplate({ isVisible: false, color: undefined })
                  }
                >
                  Bleu Classique
                </button>

                <button
                  className="btn btn-outline
                   hover:bg-yellow-700  text-yellow-900 
                     "
                  onClick={() => {
                    changeTemplate("coffee");
                  }}
                  disabled={colorChanging}
                  onMouseEnter={() =>
                    setPreviewTemplate({
                      isVisible: true,
                      color: colorSets.coffee,
                    })
                  }
                  onMouseLeave={() =>
                    setPreviewTemplate({ isVisible: false, color: undefined })
                  }
                >
                  Coffee
                </button>
                <button
                  className="btn btn-outline hover:bg-gray-500  text-gray-700  
                    "
                  onClick={() => {
                    changeTemplate("skyGrey");
                  }}
                  disabled={colorChanging}
                  onMouseEnter={() =>
                    setPreviewTemplate({
                      isVisible: true,
                      color: colorSets.skyGrey,
                    })
                  }
                  onMouseLeave={() =>
                    setPreviewTemplate({ isVisible: false, color: undefined })
                  }
                >
                  Sky Grey
                </button>
                <button
                  className="btn btn-outline hover:bg-red-400  text-red-600 "
                  onClick={() => {
                    changeTemplate("strawberry");
                  }}
                  disabled={colorChanging}
                  onMouseEnter={() =>
                    setPreviewTemplate({
                      isVisible: true,
                      color: colorSets.strawberry,
                    })
                  }
                  onMouseLeave={() =>
                    setPreviewTemplate({ isVisible: false, color: undefined })
                  }
                >
                  Strawberry
                </button>
                <button
                  className="btn btn-outline hover:bg-yellow-500  text-yellow-700  "
                  onClick={() => {
                    changeTemplate("sepia");
                  }}
                  disabled={colorChanging}
                  onMouseEnter={() =>
                    setPreviewTemplate({
                      isVisible: true,
                      color: colorSets.sepia,
                    })
                  }
                  onMouseLeave={() =>
                    setPreviewTemplate({ isVisible: false, color: undefined })
                  }
                >
                  Sepia
                </button>
                <button
                  className="btn btn-outline hover:bg-gray-700 text-gray-900"
                  onClick={() => {
                    changeTemplate("dark");
                  }}
                  disabled={colorChanging}
                  onMouseEnter={() =>
                    setPreviewTemplate({
                      isVisible: true,
                      color: colorSets.dark,
                    })
                  }
                  onMouseLeave={() =>
                    setPreviewTemplate({ isVisible: false, color: undefined })
                  }
                >
                  Dark
                </button>
              </div>
              <div className="cardinfo-template-title">
                <span>{t("template_custom")}</span>
                {createTemplateTips}
              </div>

              <div className="form-control">
                <div className="input-group flex flex-row justify-center">
                  <select
                    className="select select-bordered"
                    value={templateTemp}
                    onChange={(e) => setTemplateTemp(e.target.value)}
                  >
                    <option disabled selected>
                      {t("pick_a_template")}
                    </option>
                    {templates.map((template) => {
                      return (
                        <option value={template.id}>{template.name}</option>
                      );
                    })}
                  </select>
                  <button
                    className="btn"
                    onClick={handleSubmit}
                    disabled={templates.length === 0}
                  >
                    {" "}
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CardInfo;
