import { AuthContext } from "AuthProvider";
import { CropImage } from "components/form/CropImage";
import { templateValidationSchema } from "components/form/schemas/TemplateValidationSchema";
import { ImageUpload } from "components/ImageUpload";
import Loading from "components/Loading";
import { basic } from "components/palette";
import {
  doc,
  Firestore,
  getFirestore,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage";
import { Form, Formik } from "formik";
import { getTemplate } from "helpers/firestoreHelper";
import { randomString } from "helpers/functionsHelper";
import { useContext, useEffect, useState } from "react";
import { ImageListType } from "react-images-uploading";
import { Router, useHistory, useParams } from "react-router";
import { CroppedImage, Logo, Template } from "types";
import BusinessCard from "components/BusinessProfile/BusinessCard";
import { avatars } from "./avatars";
import CreateCardButton from "components/BusinessProfile/CreateCardButton";
import AddContact from "components/BusinessProfile/AddContact";
import InfosPanel from "components/BusinessProfile/InfosPanel";
import SocialGrid from "components/BusinessProfile/SocialGrid";
import { MyColorInput, MyTextInput } from "components/form/FormComponents";
import RouterPrompt from "components/RouterPrompt";
import { PrimaryButton } from "components/buttons/buttons";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

interface IParams {
  templateid: string;
}

function TemplateForm() {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.template.template_form",
  });
  const [images, setImages] = useState<ImageListType>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(false);
  const [displayImage, setDisplayImage] = useState(false);

  const { templateid } = useParams<IParams>();
  const authContext = useContext(AuthContext);
  let companyid = "";
  if (authContext) {
    companyid = authContext.companyid;
  }

  const [croppedImage, setCroppedImage] = useState<CroppedImage>({
    blobUrl: "",
    base64: "",
    type: "",
  });
  const [initialValues, setInitialValues] = useState<Template & Logo>({
    id: "",
    companyid: "",
    name: "",
    colors: basic,
    logo: false,
    logoUrl: "",
  });
  const [logo, setLogo] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>();
  const onChange = (imageList: any) => {
    // data for submit
    setImages(imageList);
  };
  const avatar = avatars[Math.floor(Math.random() * avatars.length)];

  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  var temp = history.location.pathname.split("/");
  const editing = !(temp[temp.length - 1] === "new");
  const [routerHandler, setRouterHandler] = useState(true);

  useEffect(() => {
    const queryTemplate = async () => {
      const template = await getTemplate(templateid);
      if (template === undefined) {
        return;
      }
      setImgUrl(template?.logoUrl);
      setLogo(template.logo);
      setInitialValues(template);
    };
    if (editing) {
      queryTemplate();
    }
    setIsLoading(false);

    //eslint-disable-next-line
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    handleImage();
  }, [images]);

  const handleImage = () => {
    if (!images[0] || !images[0].file) {
      setDisplayImage(false);
      return 0;
    }
    const type = images[0].file.type.split("/");
    if (
      type[0] != "image" ||
      !["png", "jpg", "jpeg", "svg+xml"].includes(type[1])
    ) {
      setDisplayImage(false);
      setImageTypeError(true);
      return 0;
    }
    setDisplayImage(true);
    setImageTypeError(false);
  };

  return (
    <div>
      <RouterPrompt
        condition={routerHandler}
        title={t("confirm_quit_title")}
        message={t("confirm_quit_message")}
        okText={t("confirm_quit_ok")}
        cancelText={t("confirm_quit_cancel")}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="cardform-actionbar">
            <button className="cardform-button-back" onClick={handleGoBack}>
              {t("back")}
            </button>
          </div>
          <div className="cardform-title-container">
            <h1 className="cardform-title">
              {editing ? t("title_edit") : t("title_create")}
            </h1>
          </div>

          <div className="cardform-input-container">
            <input
              type="checkbox"
              checked={logo}
              onChange={(e) => {
                setLogo(e.target.checked);
                setImageTypeError(false);
              }}
              style={{ marginRight: "1rem" }}
            />
            <span> {t("logo_description")} </span>
          </div>
          {logo ? (
            <>
              <div className="cardform-image-container">
                {imgUrl ? <img src={imgUrl} alt="logo" width={300} /> : null}
              </div>
              <ImageUpload value={images} onChange={onChange} />
              {displayImage ? (
                <CropImage
                  imageUrl={images[0] ? images[0].data_url : null}
                  setCroppedImage={setCroppedImage}
                  aspect={6 / 1}
                />
              ) : null}
            </>
          ) : null}

          {imageTypeError && !displayImage && logo && (
            <div className="flex items-center justify-center py-3">
              <span className="self-center font-semi-bold text-red-600">
                {t("picture_error_type")}
              </span>
            </div>
          )}

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={templateValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const db = getFirestore();
              // Check if image
              if (logo) {
                if (!editing && croppedImage.blobUrl === "") {
                  toast.error(t("error_no_image"));
                  return;
                }
                if (
                  editing &&
                  images[0] !== undefined &&
                  croppedImage.blobUrl === ""
                ) {
                  toast.error(t("error_validation_missing"));
                  return;
                }
              }
              const type = "jpg";
              setIsSubmitting(true);

              var ID = "_" + randomString(6);
              let templateid;
              editing
                ? (templateid = initialValues.id)
                : (templateid = companyid + ID);

              const storage = getStorage();
              const imageRef = ref(
                storage,
                `${companyid}/${templateid}.${type}`
              );

              if (editing && images.length !== 0 && values.logoUrl !== "") {
                const prevImageRef = ref(
                  storage,
                  `${companyid}/${templateid}.jpg`
                );
                await deleteObject(prevImageRef);
              }
              if (images[0]?.file && logo) {
                let blob = await fetch(croppedImage.blobUrl)
                  .then((r) => r.blob())
                  .then(
                    (blobFile) =>
                      new File([blobFile], "Hey", {
                        type: "image/jpeg",
                      })
                  );
                await uploadBytes(imageRef, blob);
              }
              type TemplateData = Omit<Template, "id">;
              const data: TemplateData = {
                colors: values.colors,
                companyid: companyid,
                name: values.name,
                logo: logo,
                edited: Timestamp.now(),
              };

              const templateRef = doc(db, `templates/${templateid}`);
              await setDoc(templateRef, data, { merge: true });
              toast.success(
                editing
                  ? t("success_template_edited")
                  : t("success_template_created")
              );

              editing
                ? window.analytics.track("Template edited", {})
                : window.analytics.track("Template added", {});

              setSubmitting(false);
              setIsSubmitting(false);

              history.replace(`/dashboard/templates`);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form autoComplete="off">
                  <div className="form-container">
                    <MyTextInput
                      label={t("model_name")}
                      name="name"
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      data-lpignore="true"
                      data-form-type="other"
                    />
                    <div className="form-businesscard-container">
                      <div className="cardinfo-businesscard">
                        <BusinessCard
                          colors={values.colors.businesscard}
                          company={t("company")}
                          firstname={t("firstname")}
                          lastname={t("lastname")}
                          imgUrl={avatar}
                          job={t("job")}
                        />
                      </div>
                      <div className="form-businesscard-colors">
                        <MyColorInput
                          label={t("background")}
                          name="colors.businesscard.background"
                          type="text"
                          placeholder=""
                        />

                        <MyColorInput
                          label={t("font") + " 1"}
                          name="colors.businesscard.fontcolor"
                          type="text"
                          placeholder=""
                        />
                        <MyColorInput
                          label={t("font") + " 2"}
                          name="colors.businesscard.subfontcolor"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-businesscard-container">
                      <SocialGrid colors={values.colors.socialbutton} />
                      <div className="form-businesscard-colors">
                        <MyColorInput
                          label={t("background")}
                          name="colors.socialbutton.background"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-businesscard-container">
                      <AddContact colors={values.colors.addcontact} />
                      <div className="form-businesscard-colors">
                        <MyColorInput
                          label={t("background")}
                          name="colors.addcontact.background"
                          type="text"
                          placeholder=""
                        />
                        <MyColorInput
                          label={t("font")}
                          name="colors.addcontact.fontcolor"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-businesscard-container">
                      <InfosPanel
                        colors={values.colors.infospanel}
                        phone="06 12 34 56 89"
                        mail="contact@justonecard.fr"
                        website="https://www.justonecard.fr"
                      />

                      <div className="form-businesscard-colors">
                        <MyColorInput
                          label={t("background")}
                          name="colors.infospanel.background"
                          type="text"
                          placeholder=""
                        />
                        <MyColorInput
                          label={t("font")}
                          name="colors.infospanel.fontcolor"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-businesscard-container">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          backgroundColor: values.colors.background.behind,
                          width: 320,
                          height: 568,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            borderRadius: "18px 18px 0px 0px ",
                            backgroundColor: values.colors.background.front,
                            width: 320,
                            height: 400,
                          }}
                        ></div>
                      </div>
                      <div className="form-businesscard-colors">
                        <MyColorInput
                          label={t("background")}
                          name="colors.background.behind"
                          type="text"
                          placeholder=""
                        />
                        <MyColorInput
                          label={t("front")}
                          name="colors.background.front"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="cardform-button-submit-container">
                      <PrimaryButton
                        type="submit"
                        disabled={isSubmitting}
                        title={isSubmitting ? t("saving") : t("save")}
                        onClick={() => {
                          setRouterHandler(false);
                        }}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
}

export default TemplateForm;
