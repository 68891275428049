import UserTable from "components/UserTable";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  where,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import { AuthContext } from "AuthProvider";
import { Owner } from "types";
import { useHistory } from "react-router";
import { useMobileMediaQuery } from "helpers/responsiveHelper";
import { useTranslation } from "react-i18next";

function Users() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.users",
  });
  const authContext = useContext(AuthContext);
  const uid = authContext?.user?.uid;
  const history = useHistory();

  const [employees, setEmployees] = useState<Owner[]>([]);
  const [loading, setLoading] = useState(true);
  //Data

  useEffect(() => {
    window.analytics.page("Users");
  }, []);
  useEffect(() => {
    const getDataFromDB = async () => {
      const db = getFirestore();
      // Link admin to his company
      const linkCompanyRef = doc(db, `companiesadmin/${uid}`);
      const snapshot = await getDoc(linkCompanyRef);
      const data = snapshot.data();
      const id = data?.companyid;

      // Get employees infos
      const employeesRef = query(
        collection(db, "owners"),
        where("company.id", "==", id)
      );
      const employeesSnapshot = await getDocs(employeesRef);
      employeesSnapshot.forEach((elmt) => {
        const data = elmt.data() as Owner;
        setEmployees((c) => {
          return [
            ...c,
            {
              id: elmt.id,
              ...data,
              taps: data?.taps ? data.taps : 0,
              state: data?.active ? t("activated") : t("deactivated"),
            },
          ];
        });
      });

      setLoading(false);
    };
    getDataFromDB();

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!loading && employees.length === 1) {
      const employee = employees[0];
      localStorage.setItem('employees', employees.length.toString());
      history.push(`/dashboard/users/${employee.id}`);
    }
  }, [employees, history, loading]);
  // Columns
  const columns: any = useMemo(
    () => [
      {
        Header: t("firstname"), // optional string or valid JSX
        accessor: "firstname", // required(at least for beginners) => row["firstname"]
        maxWidth: 140,
        minWidth: 100,
      },
      {
        Header: t("lastname"),
        accessor: "lastname",
        // Use our custom `fuzzyText` filter on this column
        filter: "fuzzyText",
        maxWidth: 140,
        minWidth: 100,
      },

      {
        Header: t("card_activated"),
        accessor: "state",
        maxWidth: 150,
        minWidth: 100,
      },
      {
        Header: t("number_of_uses"),
        accessor: "taps",
        maxWidth: 150,
        minWidth: 100,
      },
    ],
    [t]
  );

  const halfOfScreen = window.innerWidth / 2;

  const mobileColumns: any = useMemo(
    () => [
      {
        Header: t("firstname"), // optional string or valid JSX
        accessor: "firstname", // required(at least for beginners) => row["firstname"]

        width: halfOfScreen,
      },
      {
        Header: t("lastname"),
        accessor: "lastname",

        width: halfOfScreen,
      },
    ],
    [halfOfScreen, t]
  );

  const handleNavigationToUserInfo = (userid: string) => {
    const user = employees.find((elmt) => elmt.id === userid);
    localStorage.setItem('employees', employees.length.toString())
    if (user?.active) {
      history.push(`/dashboard/users/${userid}`);
    } else {
      history.push(`/dashboard/users/${userid}/new`);
    }
  };

  return (
    <>
      <UserTable
        columns={useMobileMediaQuery() ? mobileColumns : columns}
        data={employees}
        handleNavigation={handleNavigationToUserInfo}
      />
    </>
  );
}

export default Users;
