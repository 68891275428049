import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "AuthProvider";
import "./stylesheets/main.scss";
import "./helpers/i18/i18n";
import { init as sentryInit } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


process.env.NODE_ENV === "production" &&
  sentryInit({
    dsn: "https://1fe70d1f5c2847b390ddae87a2ba0d7e@o1414856.ingest.sentry.io/6755181",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });


declare global {
  interface Window {
    analytics: any;
    $crisp: any;
    CRISP_WEBSITE_ID: string;
  }
}

ReactDOM.render(
  <AuthProvider>
    <Router getUserConfirmation={() => {}}>
      <App />
    </Router>
  </AuthProvider>,
  document.getElementById("root")
);
