import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { Card, Owner } from "types";
import Loading from "components/Loading";
import PermissionError from "components/PermissionError";
import { useHistory } from "react-router";
import BusinessCard from "components/BusinessProfile/BusinessCard";
import { QRCodeCanvas } from "qrcode.react";
import { blueClassic, skyGrey } from "components/palette";
import { getTemplate } from "helpers/firestoreHelper";
import leaves from "../assets/leavesblack.png";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { MyTextInput } from "components/form/FormComponents";
import toast from "react-hot-toast";
import AddNewProfile from "components/AddNewProfile";
import { handleSaveQrCode } from "helpers/functionsHelper";

interface IParams {
  userid: string;
}
export interface IBase64Image {
  base64: string;
  type: string;
}
function UserInfo() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.user_info",
  });
  const [checked, setChecked] = useState(false);

  const { userid } = useParams() as IParams;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorPermissions, setErrorPermissions] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState<Owner>();
  const [cards, setCards] = useState<Card[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    website: "",
  });
  let conditionalRendering = <Loading />;

  useEffect(() => {
    window.analytics.page("User infos");
  }, []);

  const employees = localStorage.getItem("employees") != "1";

  useEffect(() => {
    const queryUserInfoFromDB = async () => {
      const db = getFirestore();
      const userRef = doc(db, `owners/${userid}`);

      try {
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot.data() as Owner;
        setUser({ id: userSnapshot.id, ...userData });

        if (!userSnapshot.exists()) {
          setErrorPermissions(true);
          return;
        }

        if (userData?.type === "website") {
          setChecked(true);
        }
        userData?.website &&
          setInitialValues({
            website: userData.website,
          });
      } catch (error: any) {
        if (error.code === "permission-denied") {
          setErrorPermissions(true);
        }
        return;
      }

      const cardRef = collection(db, `cards`);
      const cardQuery = query(cardRef, where("owner.id", "==", userid));
      const cardSnapshot = await getDocs(cardQuery);

      if (cardSnapshot.empty) {
        history.push(`${history.location.pathname}/new`);
        setIsLoading(false);
        return;
      }

      let cardsData: Card[] = [];
      cardSnapshot.forEach(async (elmt) => {
        let cardData = {
          id: elmt.id,
          ...elmt.data(),
        } as Card;
        if (cardData?.templateid) {
          const template = await getTemplate(cardData.templateid);

          cardData = {
            ...cardData,
            colors: template ? template.colors : skyGrey,
          };
        }

        cardsData = [cardData, ...cardsData];
        setCards((c) => [...c, cardData]);
      });

      setIsLoading(false);
    };
    queryUserInfoFromDB();
    //eslint-disable-next-line
  }, []);

  const handleNavigationToNewCard = () => {
    history.push(`${userid}/new`);
  };

  const handleNavigationToCardInfo = (card: Card) => {
    history.push(`${userid}/${card.id}`);
  };

  const [switchingMode, setSwitchingMode] = useState(false);

  const handleChange = async (nextChecked: boolean) => {
    setSwitchingMode(true);
    const db = getFirestore();
    const userRef = doc(db, `owners/${userid}`);
    (initialValues.website !== "" || !nextChecked) &&
      (await updateDoc(userRef, { type: nextChecked ? "website" : "card" }));

    toast(nextChecked ? t("redirect_mod_info") : t("business_card_mod_info"));
    setChecked(nextChecked);
    setSwitchingMode(false);
  };

  if (errorPermissions) conditionalRendering = <PermissionError />;

  /**
   * Function to navigate to the profile
   */
  const handleNavigateToProfile = () => {
    window.location.href = `https://app.justonecard.fr/${userid}`;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      {isLoading || errorPermissions || user === undefined ? (
        conditionalRendering
      ) : (
        <div className="">
          <div
            className={
              employees ? "userinfo-actionbar-back" : "userinfo-actionbar"
            }
          >
            {employees && (
              <button onClick={handleGoBack} className="btn btn-primary btn-sm">
                {t("back")}
              </button>
            )}
            <button
              className="btn btn-sm btn-secondary mr-2"
              onClick={handleNavigationToNewCard}
            >
              {t("new_profile")}
            </button>
          </div>
          <div className="userinfo-info-container">
            <h1 className="userinfo-name">
              {user.firstname} {user.lastname.toUpperCase()}
            </h1>

            <div className="bg-white p-8">
              <div className="flex items-center flex-col">
                <QRCodeCanvas
                  value={`https://app.justonecard.fr/${userid}`}
                  size={256}
                  level="H"
                  id="qrcode"
                  imageSettings={{
                    src: leaves,
                    excavate: true,
                    height: 60,
                    width: 60,
                  }}
                />

                <div className="flex flex-row justify-center items-center mt-1 p-4 space-x-3">
                  <a
                    href={`https://app.justonecard.fr/${userid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="btn btn-primary btn-outline"
                      onClick={() => {}}
                    >
                      {t("profile_digital")}
                    </button>
                  </a>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleSaveQrCode({
                        filename: `${user?.firstname}-${user?.lastname}-JustOneCard`,
                      })
                    }
                  >
                    {t("download")}
                  </button>
                </div>
              </div>
            </div>
            <div className="userinfo-website-container">
              <h2 className="userinfo-website-title">
                {t("switch_to_website")}
              </h2>
              <div className="userinfo-website-content">
                <div className="flex w-full justify-center ">
                  <div
                    className={`btn btn-primary  ${
                      checked ? "btn-outline" : ""
                    } ${switchingMode ? "loading" : ""}`}
                    onClick={() => checked && handleChange(!checked)}
                  >
                    Carte de visite
                  </div>
                  <div className="divider divider-horizontal">OR</div>
                  <div
                    className={`btn btn-primary  ${
                      checked ? "" : "btn-outline"
                    } ${switchingMode ? "loading" : ""}`}
                    onClick={() => !checked && handleChange(!checked)}
                  >
                    Site internet
                  </div>
                </div>

                {checked && (
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                      website: Yup.string().url(),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      const db = getFirestore();
                      const userRef = doc(db, `owners/${userid}`);
                      const promise1 = await updateDoc(userRef, {
                        website: values.website,
                      });
                      const promise2 = await updateDoc(userRef, {
                        type: checked ? "website" : "card",
                      });
                      await Promise.all([promise1, promise2]);
                      toast.success(t("website_updated"));
                    }}
                  >
                    {({ values, setFieldValue, isSubmitting }) => {
                      return (
                        <Form>
                          <div className="cardform-input-container ">
                            <MyTextInput
                              label="Site Internet"
                              name="website"
                              type="text"
                              placeholder="https://justonecard.fr"
                            />

                            <div className="cardform-button-submit-container">
                              <button
                                type="submit"
                                className={`btn btn-primary ${
                                  isSubmitting ? "loading" : ""
                                }`}
                                title={t("save")}
                              >
                                Enregistrer
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>
            <div
              className="userinfo-help-container"
              style={
                checked
                  ? {
                      opacity: 0.1,
                    }
                  : {}
              }
            >
              <h1 className="userinfo-help-title">{t("tips")}</h1>
              <ol>
                <li>{t("tips_1")}</li>
                <li>{t("tips_2")}</li>
                <li>{t("tips_3")}</li>
              </ol>
            </div>
          </div>
          <div>
            <div className="userinfo-cardlist">
              {cards
                .sort((c1, c2) =>
                  c1.active === c2.active ? 0 : c1.active ? -1 : 1
                )
                .map((card) => {
                  if (card.imageUrl === undefined) {
                    return <div></div>;
                  }
                  return (
                    <div
                      className="userinfo-businesscard"
                      onClick={() => handleNavigationToCardInfo(card)}
                      key={card.id}
                      style={{
                        ...(card.active
                          ? {
                              borderRadius: "35px",
                              boxShadow: "0px 0px 10px 15px #1A675D",
                            }
                          : {}),
                        ...(checked
                          ? {
                              opacity: 0.1,
                            }
                          : {}),
                      }}
                    >
                      <BusinessCard
                        firstname={card.firstname}
                        lastname={card.lastname}
                        job={card.job}
                        company={card.company}
                        imgUrl={card.imageUrl}
                        colors={
                          card.colors
                            ? card.colors.businesscard
                            : blueClassic.businesscard
                        }
                      />
                    </div>
                  );
                })}
              <AddNewProfile onClick={handleNavigationToNewCard} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserInfo;
