import errorPicture from "assets/404Error.svg";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export default function PermissionError() {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.permission_error",
  });
  const history = useHistory();
  return (
    <div className="flex-col justify-center items-center w-full flex md:h-2/5">
      <img className="md:w-2/5 w-1/1" src={errorPicture} alt=""/>
      <h2 className="md:text-xl text-lg mx-4 md:mx-0 text-gray-500 mb-10 font-semibold text-center">{t("we_are_sorry")}</h2>
      <button className="btn btn-primary " onClick={() => history.push('/dashboard')} >{t("go_back")}</button>
    </div>
  )
}
