import { AuthContext } from "AuthProvider";
import Loading from "components/Loading";
import TotalTaps from "components/statistics/TotalTaps";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Statistics() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.statistics.statistics",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSignedUp, setIsSignedUp] = useState(true);
  const [isSigningUp, setIsSingingUp] = useState(false);
  const db = getFirestore();

  const authContext = useContext(AuthContext);
  let companyId = "";
  let email = "";
  if (authContext && authContext.user?.email) {
    companyId = authContext.companyid;
    email = authContext.user?.email;
  }

  useEffect(() => {
    window.analytics.page("Statistics");

    const checkAlreadySignedUp = async () => {
      const ref = doc(db, `betaforstats/${companyId}`);
      const betaforstatsDoc = await getDoc(ref);
      if (!betaforstatsDoc.exists()) {
        setIsSignedUp(false);
      }
      setIsLoading(false);
    };
    checkAlreadySignedUp();
  });

  const handleSignInForStats = async () => {
    // get date with firestore fieldvalue
    window.analytics.track("Sign in for stats");
    const ref = doc(db, `betaforstats/${companyId}`);
    setIsSingingUp(true);
    await setDoc(ref, { email, createdAt: serverTimestamp() });
    setIsSingingUp(false);
    setIsSignedUp(true);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="feedback-actionbar">
            <p style={{ color: "white" }}></p>
          </div>
          <div className="feedback-main-container">
            <div className="feedback-container">
              <div className="feedback-box">
                <caption>
                  <p>{t("caption_title")}</p>
                  <p>{t("caption_description")}</p>

                  <br />
                </caption>

                {isSignedUp ? (
                  <span>{t("caption_success")}</span>
                ) : (
                  <button
                    type="button"
                    onClick={handleSignInForStats}
                    className="feedback-button-send"
                    disabled={isSigningUp}
                  >
                    {isSigningUp
                      ? t("pre_registration_signing_button")
                      : t("pre_registration_button")}
                  </button>
                )}
              </div>
            </div>
            <div className="statistics-totaltaps-container">
              <TotalTaps companyid={companyId} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Statistics;
