import {getStorage, ref, getDownloadURL, deleteObject} from "firebase/storage";

const storage = getStorage();

/**
 * Give the URL of the image in the path "{ownerid}/{cardid}.{imageType}"
 * @param ownerid ID of the Owner
 * @param cardid ID of the Card
 * @param imageType Type of the image
 */
export const getImageUrl = async (
  ownerid: string,
  cardid: string,
  imageType: string
) => {
  const imageRef = ref(storage, `${ownerid}/${cardid}.${imageType}`);
  const imageUrl = await getDownloadURL(imageRef);
  return imageUrl;
};

/**
 *
 * @param ownerid ID of the Owner
 * @param cardid ID of the Card
 * @param extension Extension of this file (imageType or vcf)
 */
export const deleteStorageDoc = async (
  ownerid: string,
  cardid: string,
  extension: "jpg" | "png" | "vcf" | "pdf"
) => {
  const docRef = ref(storage, `${ownerid}/${cardid}.${extension}`);
  await deleteObject(docRef);
};
