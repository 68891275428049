import { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MyTextInput } from "components/form/FormComponents";
import { Form, Formik } from "formik";
import { ForgotPasswordValidationSchema } from "components/form/schemas/ForgotPasswordValidationSchema";

function ForgotPassword() {
  const auth = getAuth();
  const history = useHistory();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.auth.forgot_password",
  });
  useEffect(() => {
    window.analytics.page("Reset password");
  }, []);

  const handleNavigationToSignIn = () => {
    history.push("login");
  };

  const [confirmationShown, setConfirmationShown] = useState(false);

  return (
    <div className=" flex flex-col justify-center items-center mt-10 ">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        enableReinitialize={true}
        validationSchema={ForgotPasswordValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const { email } = values;
          try {
            await sendPasswordResetEmail(auth, email);
            window.analytics.track("Password reset", {
              email,
            });
            toast.success(t("success", { email }));
            setConfirmationShown(true);
          } catch (e) {
            toast.error(t("error"));
          }

          setSubmitting(false);
        }}
      >
        {({ submitForm, values }) => {
          return (
            <Form>
              {!confirmationShown ? (
                <div className="form-container relative">
                  <div className="form-description-container">
                    <div className="form-description-title">{t("title")}</div>
                    <div className="form-description-subtitle">
                      {t("subtitle")}
                    </div>
                  </div>
                  <div className="w-full flex flex-col items-center m-4">
                    <MyTextInput label={t("email")} name="email" type="email" />
                  </div>

                  <div className="form-submit-container">
                    <button type="submit" className="btn btn-primary">
                      Réinitialiser le mot de passe
                    </button>
                  </div>
                </div>
              ) : (
                <div className="">
                  <h3 className="font-bold text-lg">Demande envoyée !</h3>

                  <div className="modal-action">
                    <label
                      className="btn btn-primary"
                      onClick={handleNavigationToSignIn}
                    >
                      {"Retour à la page de connexion"}
                    </label>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>

      {confirmationShown ? null : (
        <button
          className="btn btn-secondary btn-link btn-sm  "
          onClick={handleNavigationToSignIn}
          type="button"
        >
          {t("button_for_connexion")}
        </button>
      )}
    </div>
  );
}

export default ForgotPassword;
