import { Route, Switch, Redirect } from "react-router-dom";
import SignIn from "pages/auth/SignIn";
import ForgotPassword from "pages/auth/ForgotPassword";
import AuthTopBar from "components/AuthTopBar";

export const AuthRoutes = () => {
  return (
    <>
      <div className=" flex justify-center min-h-screen relative">
        <div className=" max-w-5xl w-full min-h-screen relative ">
          <AuthTopBar />
          <Switch>
            <Route
              exact
              path="/auth/reset-password"
              component={ForgotPassword}
            />
            <Route exact path="/auth/login" component={SignIn} />

            <Redirect to="/auth/login" from="/auth" />
          </Switch>
        </div>
      </div>
    </>
  );
};
