import imageCompression from "browser-image-compression";
import {IBase64Image} from "pages/UserInfo";

export const imageUrlToBase64 = async (
  imageUrl: string,
  setBase64Image: (
    value: React.SetStateAction<IBase64Image | undefined>
  ) => void
) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", imageUrl);
  xhr.responseType = "blob";
  xhr.send();
  xhr.addEventListener("load", async () => {
    var reader = new FileReader();

    reader.readAsDataURL(xhr.response);
    reader.addEventListener("loadend", function () {
      const result = reader.result as string;
      let temp = result.split(";base64,");
      const type = temp[0].slice(5);
      const base64 = temp[1];

      setBase64Image({type: type, base64: base64});
    });
  });
};

export const compressImage = async (imageFile: File) => {
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const compressedFile = await imageCompression(imageFile, options);
  return compressedFile;
};
