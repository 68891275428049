import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

interface IRouterPrompt {
  condition: boolean;
  title: string;
  message: string;
  okText: string;
  cancelText: string;
}

export default function RouterPrompt({
  condition,
  title,
  message,
  okText,
  cancelText,
}: IRouterPrompt) {
  const history = useHistory();

  useEffect(() => {
    if (condition) {
      history.block((prompt) => {
        alert(prompt.pathname);
        return "true";
      });
    } else {
      history.block(() => {});
    }
    return () => {
      history.block(() => {});
    };
  }, [history, condition]);

  const alert = (pathname: string) =>
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-box  w-11/12 max-w-5xl">
            <h3 className="font-bold text-lg">{title}</h3>
            <p className="py-4">{message}</p>
            <div className="modal-action">
              <button
                className="btn btn-primary"
                onClick={() => {
                  onClose();
                }}
              >
                {cancelText}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  history.block(() => {});
                  history.push(pathname);
                  onClose();
                }}
              >
                {okText}
              </button>
            </div>
          </div>
        );
      },
    });

  return <></>;
}
