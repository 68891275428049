import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "components/LanguageSwitcher";

function Settings() {
  const history = useHistory();
  const auth = getAuth();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.settings.settings",
  });

  useEffect(() => {
    window.analytics.page("Settings");
  }, []);

  const signOutWithFirebase = async () => {
    window.analytics.track("User logout");
    await signOut(auth);
  };

  const handleNavigationToEditPassword = () => {
    history.push("settings/editpassword");
  };

  return (
    <>
      <div className="settings-actionbar">
        <span className="text-white cardinfo-email-span">
          {auth.currentUser?.email}
        </span>
        <div></div>
        <button
          onClick={signOutWithFirebase}
          className="btn btn-error btn-sm"
          data-cy="signout-button"
        >
          {t("disconnect")}
        </button>
      </div>
      <div className="settings-container ">
        <button
          onClick={handleNavigationToEditPassword}
          className="btn btn-accent"
        >
          {t("button_to_edit_password")}
        </button>
      </div>
      <div className="settings-languageswitcher-container">
        <LanguageSwitcher />
      </div>
    </>
  );
}

export default Settings;
