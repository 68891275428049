import {
  MdFeedback,
  MdHelp,
  MdOutlineQueryStats,
  MdPalette,
  MdSettings,
} from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import joc from "assets/joc_icon_white.svg";
import { useHistory, useLocation } from "react-router-dom";
import { Default } from "helpers/responsiveHelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function LeftBar() {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.left_bar",
  });
  const history = useHistory();
  const [tabActive, setTabActive] = useState("Users");
  const location = useLocation();
  useEffect(() => {
    const tab = location.pathname.split("/")[2];
    setTabActive(tab);
  }, [location]);

  const handleNavigationToUsers = () => {
    localStorage.setItem("user_table_index", "0");
    history.push("/dashboard/users");
  };
  const handleNavigationToTemplate = () => {
    history.push("/dashboard/templates");
  };
  const handleNavigationToHelp = () => {
    history.push("/dashboard/help");
  };
  const handleNavigationToFeedback = () => {
    history.push("/dashboard/feedback");
  };
  const handleNavigationToSettings = () => {
    history.push("/dashboard/settings");
  };
  const handleNavigationToStats = () => {
    history.push("/dashboard/stats");
  };

  return (
    <div className="leftbar-container ">
      <div className="leftbar-flex">
        <div className="leftbar-section-1 ">
          <div
            className="leftbar-logo-container"
            onClick={handleNavigationToUsers}
          >
            <img className="leftbar-logo" src={joc} alt="joc logo" />
          </div>

          <LeftBarIcon
            icon={<FaUsers size="28" />}
            text={t("employees")}
            handleNavigation={handleNavigationToUsers}
            dataCyForButton="users-icon"
            active={tabActive === "users"}
          />
          <Default>
            <LeftBarIcon
              icon={<MdPalette size="28" />}
              text={t("customization")}
              handleNavigation={handleNavigationToTemplate}
              dataCyForButton="templates-icon"
              active={tabActive === "templates"}
            />
          </Default>
          <LeftBarIcon
            icon={<MdOutlineQueryStats size="28" />}
            text={t("statistics")}
            handleNavigation={handleNavigationToStats}
            dataCyForButton="stats-icon"
            active={tabActive === "stats"}
          />
        </div>
        <div className="leftbar-settings-container flex flex-row md:flex-col ">
          <Default>
            <LeftBarIcon
              icon={<MdHelp size="28" />}
              text={t("help")}
              handleNavigation={handleNavigationToHelp}
              dataCyForButton="help-icon"
              active={tabActive === "help"}
            />
          </Default>

          <Default>
            <LeftBarIcon
              icon={<MdFeedback size="28" />}
              text={t("feedback")}
              handleNavigation={handleNavigationToFeedback}
              dataCyForButton="feedback-icon"
              active={tabActive === "feedback"}
            />
          </Default>

          <LeftBarIcon
            icon={<MdSettings size="28" />}
            text={t("settings")}
            handleNavigation={handleNavigationToSettings}
            dataCyForButton="settings-icon"
            active={tabActive === "settings"}
          />
        </div>
      </div>
    </div>
  );
}

const LeftBarIcon = ({
  icon,
  text = "tooltip 💡",
  handleNavigation,
  dataCyForButton,
  active,
}: {
  icon: any;
  text: string;
  handleNavigation: () => void;
  dataCyForButton: string;
  active: boolean;
}) => (
  <button
    className="leftbar-icon group"
    onClick={handleNavigation}
    data-cy={dataCyForButton}
    style={{
      backgroundColor: active ? "rgba(26, 103, 93)" : "",
      color: active ? "white" : "",
    }}
  >
    {icon}
    <span className="leftbar-tooltip group-hover:scale-100">{text}</span>
  </button>
);

export default LeftBar;
