import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, User } from "firebase/auth";
import { config } from "firebaseconfig";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { setUser as sentrySetUser } from "@sentry/react";

// import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
const app = initializeApp(config);
if (process.env.REACT_APP_PROJECT_ID === "justonecard-prod") {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      "6LfLT0QcAAAAAPgprl6LSOhy-02RjJnEiV-Ny1D2"
    ),
    isTokenAutoRefreshEnabled: true,
  });
}

export interface AuthObject {
  user: User | null;
  authenticated: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loadingAuthState: boolean;
  ownerid?: string;
  companyid: string;
}

export interface updateUidResult {
  id: string;
}

export const AuthContext = React.createContext<AuthObject | null>(null);
export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [companyid, setCompanyid] = useState<string>("");
  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser !== null) {
        if (firebaseUser.email != null && process.env.NODE_ENV === "production")
          sentrySetUser({ email: firebaseUser.email });
        const db = getFirestore();
        const ref = doc(db, `companiesadmin/${firebaseUser?.uid}`);
        const adminSnapshot = await getDoc(ref);
        if (adminSnapshot.exists()) {
          setCompanyid(adminSnapshot.data().companyid);
        }
      }
      setLoadingAuthState(false);
    });

    return unsubscribe;
    //eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
        companyid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
