function Loading() {
  return (
    <div className="loading-div">
      <span className="loading-arrow-0">↓</span>
      <span className="loading-arrow-1">↓</span>
      <span className="loading-arrow-2">↓</span>
      <span className="loading-arrow-3">↓</span>
      <span className="loading-arrow-4">↓</span>
    </div>
  );
}

export default Loading;
