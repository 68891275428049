import { Switch, Route, Redirect } from "react-router-dom";
import { AuthRoutes } from "routes/AuthRoutes";
import { DashboardRoutes } from "routes/DashboardRoutes";
import PrivateRoute from "routes/PrivateRoute";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <div>
        <Toaster
          toastOptions={{
            // className: "cypress-toast",
            duration: 2000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            success: {
              duration: 3000,
              style: {
                background: "#080808",
                color: "#fff",
              },
            },
          }}
        />
      </div>
      <Switch>
        <PrivateRoute path="/dashboard" component={DashboardRoutes} />

        <Route path="/auth" component={AuthRoutes} />
        <Redirect to="/dashboard" from="/" />
      </Switch>
    </>
  );
}

export default App;
