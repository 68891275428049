import React from "react";
import { ColorSet } from "types";

import { BsArrowLeftRight } from "react-icons/bs";
import { BiPlusCircle } from "react-icons/bi";

type Props = {
  colors: ColorSet | undefined;
};

function ContactButtons({ colors }: Props) {
  //Regex used to extract color from linear-gradient
  var linearGradient = colors?.addcontact.background.match(
    /#[a-zA-Z0-9]+|#[a-zA-Z0-9]+/gi
  );

  //Creation of a variable that'll contain the colors
  //extracted from the linear-gradient
  var addContactBackgroundColor = [
    linearGradient ? linearGradient[0] : "",
    linearGradient ? linearGradient[1] : "",
  ];

  return (
    <div className="contact-buttons-container my-5">
      {/* Div containing buttons to share user information */}
      <div
        style={{
          border: `2px solid ${colors?.addcontact.fontcolor}`,
          color: colors?.addcontact.fontcolor,
          background: `linear-gradient(${addContactBackgroundColor[0]}, ${addContactBackgroundColor[1]})`,
        }}
        className="button-default"
      >
        <BiPlusCircle
          size={25}
          style={{ justifySelf: "center" }}
          color={colors?.addcontact.fontcolor}
        />
        <span>Ajouter</span>
      </div>

      <button
        style={{
          color: colors?.addcontact.fontcolor,
          backgroundColor: addContactBackgroundColor[0],
        }}
        className={`button-default`}
      >
        <BsArrowLeftRight
          size={25}
          style={{ justifySelf: "center" }}
          color={colors?.addcontact.fontcolor}
        />
        <span>Echange</span>
      </button>
    </div>
  );
}

export default ContactButtons;
