import joc from "assets/joc_icon_black.svg";
import brand from "assets/brand.svg";
import { useTranslation } from "react-i18next";
import { useMobileMediaQuery } from "helpers/responsiveHelper";
function AuthTopBar() {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.auth_top_bar",
  });
  const isMobile = useMobileMediaQuery();
  return (
    <div className="flex  justify-between flex-wrap bg-teal-500 p-6 h-24">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <img className="h-10" src={joc} alt="joc logo" />
        {!isMobile && <img src={brand} alt="joc name" className=" h-7" />}
      </div>

      <div>
        <a href="https://justonecard.fr" className="btn btn-link  ">
          {t("back_to_site")}
        </a>
      </div>
    </div>
  );
}

export default AuthTopBar;
