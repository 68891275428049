import { useContext, useEffect, useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { AuthContext } from "AuthProvider";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function Feedback() {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.feedback",
  });
  const authContext = useContext(AuthContext);

  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    window.analytics.page("Feedback");
  }, []);
  const handleSendFeedback = async () => {
    const db = getFirestore();
    const feedbackRef = collection(db, "feedback");
    await addDoc(feedbackRef, {
      message: message,
      user: { uid: authContext?.user?.uid, email: authContext?.user?.email },
    });
    window.analytics.track("Feedback sent", {
      email: authContext?.user?.email,
      message: message,
    });
    setMessage("");
    toast.success(t("success"));
  };
  return (
    <>
      <div className="feedback-actionbar">
        <p style={{ color: "white" }}></p>
      </div>
      <div className="feedback-main-container">
        <div className="feedback-container">
          <div className="feedback-box">
            <caption>
              <p>{t("title")}</p>
              <p>{t("subtitle")}</p>
              <br />
            </caption>
            <textarea
              rows={5}
              cols={60}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className="feedback-textarea"
            ></textarea>
            <button
              type="button"
              onClick={handleSendFeedback}
              className="btn btn-primary mt-4"
            >
              {t("send")}
            </button>
          </div>
        </div>
        <div className="feedback-container">
          <div className="feedback-box">
            <caption>
              <p>{t("review_trustpilot")}</p>

              <br />
            </caption>
            <div className="trustpilot-widget">
              <a
                href="https://fr.trustpilot.com/review/justonecard.fr"
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Trustpilot_logo.png"
                  alt="trustpilot"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;
