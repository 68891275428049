import soundcloud from "../../assets/social_network/soundcloud_icon.svg";
import SocialButton from "./SocialButton";
import { Colors } from "types";

type Props = {
  colors: Colors;
};

/**
 * Takes the url and the OS to display the social button
 * @param url url of the profile
 * @param mobileOS OS of the device for handling deeplink
 * @returns Social Button
 */
function SoundcloudButton({ colors }: Props) {
  return (
    <SocialButton socialNetwork={soundcloud} alt="soundcloud" colors={colors} />
  );
}

export default SoundcloudButton;
