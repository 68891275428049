import { useTranslation } from "react-i18next";
import { MyTextInput } from "./FormComponents";

export const AddressInput = ({ name }: { name: string }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "components.form.adress_input",
  });

  return (
    <div className="w-full flex flex-col items-center">
      <MyTextInput
        label={t("label")}
        name={`${name}.label`}
        type="text"
        placeholder=""
      />
      <MyTextInput
        label={t("adress")}
        name={`${name}.street`}
        type="text"
        placeholder=""
      />
      <MyTextInput
        label={t("city")}
        name={`${name}.city`}
        type="text"
        placeholder=""
      />
      <MyTextInput
        label={t("zipcode")}
        name={`${name}.postalcode`}
        type="text"
        placeholder=""
      />
      <MyTextInput
        label={t("country")}
        name={`${name}.countryregion`}
        type="text"
        placeholder=""
      />
    </div>
  );
};

export default AddressInput;
